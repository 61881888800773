<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="bar"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import ChartLoaderInsert from "@/components/ChartLoaderInsert"
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ApexFreqBarDemosX",
	mixins: [globalMixin],
	components: {
		ChartLoaderInsert
	},
	props: {
		// chartdata: Array,
		// chartcolors: Array,
		fetching: Boolean,
		chosenstatistic: String,
		chosendemos: Array,
		chosendaypart: Number,
		forcedefaultcolors: {
			type: Boolean,
			default: false
		},
		chosenradiotype: {
			type: Number,
			default: 0
		},
		chosensurvey: String,
		basedata: Array,
		chartstationlist: Array,
		surveylist: Array,
		demosxaxis: {
			type: Boolean,
			default: false
		},
	},
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'bar',
				},
				legend: {
					show: true,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						endingShape: 'rounded',
						columnWidth: '80%',
					},
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: false
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				dataLabels: {
					enabled: false
				},
			},
		}
	},
	computed: {
		myDemoObs() {
			return this.store.demoObs.filter(item => this.chosendemos.includes(item.id))
		},
		chartdata() {
			let rows = []
			if(this.demosxaxis === false) { //each station is a bar group
				for(let dem of this.myDemoObs) {
					let myrow = []
					myrow = {
						name: dem.id,
						data: [],
					}
					for(let st of this.chartstationlist) {
						myrow.data.push({x: st.name, y: this.getCellData(st.id, this.chosensurvey, this.basedata, this.chosenstatistic, this.chosendaypart, dem.id, this.chosenradiotype)})
					}
					rows.push(myrow)
				}
			}
			else { //default where each survey is a bar group
				for(let st of this.chartstationlist) {
					let myrow = []
					myrow = {
						name: st.name,
						data: [],
					}
					for(let dem of this.myDemoObs) {
						myrow.data.push({x: dem.name, y: this.getCellData(st.id, this.chosensurvey, this.basedata, this.chosenstatistic, this.chosendaypart, dem.id, this.chosenradiotype)})
					}
					myrow.data.reverse()
					rows.push(myrow)
				}
			}
			return rows
		},
		chartcolors() {
			if(this.demosxaxis === false || this.forcedefaultcolors === true) {
				return this.store.defaultColors
			}
			let defaultColorCount = 0
			let colors = []
			for(let st of this.chartstationlist) {
				let arr = this.getChartColorForStation(st, defaultColorCount)
				colors.push(arr[0])
				defaultColorCount = arr[1]
			}
			return colors
		},
	},
	methods: {
		updateColours(isFirstLoad = false) {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			if(isFirstLoad) {
				this.chartoptions.colors = outcols;
			}
			else { //only for subsequent loads/updates - otherwise chart is duplicated
				this.chartoptions = {
					...this.chartoptions, ...{
						colors: outcols
					}
				};
			}
		},
		setLegend() {
			if(this.demosxaxis === false) {
				this.chartoptions.legend.show = false
			}
		},
	},
	watch: {
		chartcolors() {
			this.updateColours()
		},
	},
	mounted() {
		this.updateColours(true)
		this.setLegend()
	}
}
</script>

<style scoped>

</style>