<template>
	<div class="inner">
		<!-- cra-5nd9adlkasd-j83kad3d -->
		<div v-if="store.innerPageView === 'home'">
			<HeaderNav></HeaderNav>
			<div v-if="store.userLoggedIn === false" class="page-tile-holder">
				<LoginWidget />
			</div>
			<div v-if="store.userLoggedIn === true" class="page-tile-holder">
				<RatingsChartsAud></RatingsChartsAud>
			</div>
		</div>
		<div v-else>

		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";
import LoginWidget from "@/components/LoginWidget.vue";
import RatingsChartsAud from "@/components/views/RatingsChartsAud.vue";

export default defineComponent({
	name: "DashCRA5",
	mixins: [globalMixin],
	components: {
		RatingsChartsAud,
		LoginWidget,
		HeaderNav,
	},
	props: {},
	data() {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped></style>