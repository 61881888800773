<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="line"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ApexFreqLineSingleStn",
	mixins: [globalMixin],
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,

		chosenstatistic: String,
		chosendemoids: Array,
		chosendaypart: Number,
		chosenradiotype: {
			type: Number,
			default: 0
		},
		showmarkers: {
			type: Boolean,
			default: true,
		},
		basedata: Array,
		chosenstation: String,
		chosensurveys: Array,
		chosenmarketid: Number,
	},
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
		}
	},
	computed: {
		demoObs() {
			let dems = []
			if(this.chosendemoids && this.chosendemoids.length > 0) {
				for(let d of this.chosendemoids) {
					let dem = this.store.demoObs.find(item => item.id === d)
					if(dem) dems.push(dem)
				}
			}
			return dems
		},
		chartdata() {
			let survorder = JSON.parse(JSON.stringify(this.chosensurveys)).reverse()
			let rows = []
			if(!this.basedata || this.basedata.length === 0 || !this.chosenstation) return null
			for(let dem of this.demoObs) {
				let myrow = []
				myrow = {
					name: dem.name,
					data: [],
				}
				for(let su of survorder) {
					let bd = this.basedata.find(item => item.survcode === su.id
						&& item.daypartId === this.chosendaypart
						&& item.statistic === this.chosenstatistic
						&& item.demoId === dem.id
						&& item.audioType === 0
					)
					if(bd) {
						let valob = bd.data.find(item => item.stid === this.chosenstation)
						if (valob) {
							let val = valob.val
							if (val) {
								if(['cume','avgAud'].includes(this.chosenstatistic)) val = Math.round(val/1000)
								myrow.data.push({x: su.nicename, y: val})
							}
							else myrow.data.push({x: su.nicename, y: 0})
						}
						else myrow.data.push({x: su.nicename, y: 0})
					}
				}
				rows.push(myrow)
			}
			return rows
		},
		chartcolors() {
			if(this.store.skin === 'sca') return this.store.defaultSCASplitColors
			return this.store.defaultColors
		},
	},
	methods: {
		updateColours(isFirstLoad = false) {
			let outcols = this.store.defaultColors;
			if(this.store.skin === 'sca') outcols = this.store.defaultSCASplitColors
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			if(isFirstLoad) {
				this.chartoptions.colors = outcols;
			}
			else { //only for subsequent loads/updates - otherwise chart is duplicated
				this.chartoptions = {
					...this.chartoptions, ...{
						colors: outcols
					}
				};
			}
		},
	},
	watch: {
		chartcolors() {
			this.updateColours()
		}
	},
	mounted() {
		if(this.showmarkers === false) {
			this.chartoptions.markers.size = 0
		}
		else {
			this.chartoptions.markers.size = 4
		}
		this.updateColours(true)
	}
}
</script>

<style scoped>

</style>