<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="line"
			:options="chartoptions" :series="chartdata"></apexchart>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ApexFreqLineQtrMultiStn",
	mixins: [globalMixin],
	components: {

	},
	props: {
		fetching: Boolean,

		chosenstatistic: String,
		chosendemo: Number,
		chosendaypart: Number,
		chosenradiotype: {
			type: Number,
			default: 0
		},
		basedata: Array,
		chartstationlist: Array,
		chosensurvey: String,
		showmarkers: {
			type: Boolean,
			default: true,
		},
		ylowerzero: {
			type: Boolean,
			default: true,
		},
		showdatalabels: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				stroke: {
					curve: "straight",
					width: 2,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
		}
	},
	computed: {
		chartdata() {
			let rows = []
			if(!this.basedata || this.basedata.length === 0) return null
			for(let st of this.chartstationlist) {
				let myrow = []
				myrow = {
					name: st.name,
					data: [],
				}
				for(let qtr of this.store.qtrHrObs) {
					let valarr = this.basedata[0].data.find(item => item.stid === st.id)
					if(valarr) {
						let valob = valarr.qtrs.find(item => item.qtr === qtr.id)
						if(valob) {
							let val = valob.val
							if(val) myrow.data.push({x: qtr.name, y: val})
							else myrow.data.push({x: qtr.name, y: 0})
						}
						else myrow.data.push({x: qtr.name, y: 0})
					}
				}
				rows.push(myrow)
			}
			return rows
		},
		chartcolors() {
			let defaultColorCount = 0
			let colors = []
			for(let st of this.chartstationlist) {
				let arr = this.getChartColorForStation(st, defaultColorCount)
				colors.push(arr[0])
				defaultColorCount = arr[1]
			}
			return colors
		},
		strokeWidths() {
			if(this.store.skin === 'sca') {
				let strokeWidths = []
				for(let st of this.chartstationlist) {
					if(st.netgroupown === 'sca') strokeWidths.push(5)
					else strokeWidths.push(1)
				}
				return strokeWidths
			}
			return 3
		}
	},
	methods: {
		updateColours(isFirstLoad = false) {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			if(isFirstLoad) {
				this.chartoptions.colors = outcols
				if(this.store.skin === 'sca') {
					this.chartoptions.stroke.width = this.strokeWidths
				}
			}
			else { //only for subsequent loads/updates - otherwise chart is duplicated
				this.chartoptions = {
					...this.chartoptions, ...{
						colors: outcols
					}
				}
				if(this.store.skin === 'sca') {
					this.chartoptions = {
						...this.chartoptions, ...{
							stroke: {
								curve: "straight",
								width: this.strokeWidths,
							}
						}
					};
				}
			}
		},
	},
	watch: {
		chartcolors() {
			this.updateColours()
		}
	},
	mounted() {
		if(this.ylowerzero === true) {
			this.chartoptions.yaxis.min = 0
		}
		else {
			this.chartoptions.yaxis.min = undefined
		}
		if(this.showmarkers === false) {
			this.chartoptions.markers.size = 0
		}
		else {
			this.chartoptions.markers.size = 4
		}
		if(this.showdatalabels === true) {
			this.chartoptions.dataLabels.enabled = true
		}
		this.updateColours(true)
	}
}
</script>

<style scoped>

</style>