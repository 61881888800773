<template>
	<div>
		<div v-if="heading" class="section-title">{{heading}}</div>
		<div style="display: flex;">
			<ChartHolderDash charttitle="Share %" addclasses="third">
				<template v-slot:default>
					<ChartLoaderInsert v-if="!store.returnedData || store.returnedData.length === 0"></ChartLoaderInsert>
					<ApexFreqLineTrendMulti v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="store.returnedData" :chosendaypart="daypartid" :chosendemo="chosendemo" chosenstatistic="shareToAll"
						:chartstationlist="chartstationlist" :surveylist="surveylist" :showmarkers="false"
					></ApexFreqLineTrendMulti>
				</template>
			</ChartHolderDash>
			<ChartHolderDash charttitle="Cume 000s" addclasses="third">
				<template v-slot:default>
					<ChartLoaderInsert v-if="!store.returnedData || store.returnedData.length === 0"></ChartLoaderInsert>
					<ApexFreqLineTrendMulti v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="store.returnedData" :chosendaypart="daypartid" :chosendemo="chosendemo" chosenstatistic="cume"
						:chartstationlist="chartstationlist" :surveylist="surveylist" :showmarkers="false" :ylowerzero="false"
					></ApexFreqLineTrendMulti>
				</template>
			</ChartHolderDash>
			<ChartHolderDash charttitle="TSL (Hours per Week)" addclasses="third">
				<template v-slot:default>
					<ChartLoaderInsert v-if="!store.returnedData || store.returnedData.length === 0"></ChartLoaderInsert>
					<ApexFreqLineTrendMulti v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="store.returnedData" :chosendaypart="daypartid" :chosendemo="chosendemo" chosenstatistic="tslHrsDecimal"
						:chartstationlist="chartstationlist" :surveylist="surveylist" :showmarkers="false"
					></ApexFreqLineTrendMulti>
				</template>
			</ChartHolderDash>
		</div>
	</div>

</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ApexFreqLineTrendMulti from "@/components/ApexFreqLineTrendMulti.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";

export default defineComponent({
	name: "DaypartShareCumeTSLSplit",
	mixins: [globalMixin],
	components: {
		ChartLoaderInsert,
		ApexFreqLineTrendMulti,
		ChartHolderDash
	},
	props: {
		chartstationlist: Array,
		chosendemo: Number,
		marketid: Number,
		daypartid: Number,
		surveylist: Array,
		heading: String,
	},
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped></style>