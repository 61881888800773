<template>
	<div class="header">
		<div class="inner navlogos">
			<div class="logo-hold">
				<img v-if="store.skin.length === 0" @click="store.innerPageView = 'home'" src="@/assets/audology-rev.png" style="height: 30px;cursor: pointer;" alt="Audology Logo" />
				<img v-else-if="store.skin === 'nova'" @click="store.innerPageView = 'home'" src="@/assets/nova-ent.png" style="height: 50px;cursor: pointer;" alt="Nova Entertainment Logo" />
				<img v-else-if="store.skin === 'sca'" @click="store.innerPageView = 'sca-summary'" src="@/assets/sca-logo.svg" style="height: 40px;cursor: pointer;" alt="SCA Logo" />
				<img v-else-if="store.skin === 'arn'" @click="store.innerPageView = 'home'" src="@/assets/arn-white.png" style="height: 40px;cursor: pointer;" alt="ARN Logo" />
				<img v-else-if="store.skin === 'cra'" @click="store.innerPageView = 'home'" src="@/assets/cra-logo-white.png" style="height: 40px;cursor: pointer;" alt="CRA Logo" />
				<img v-else-if="store.skin === 'ace'" @click="store.innerPageView = 'home'" src="@/assets/ace-radio-white.png" style="height: 30px;cursor: pointer;" alt="Ace Radio Logo" />
				<div style="margin-left: 70px;">
					<slot name="filterregion"></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'

export default defineComponent({
	name: "HeaderNav",
	mixins: [globalMixin],
	components: {},
	props: {

	},
	data() {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped>
.header {
	height: 70px;
	background-color: var(--bluemain);
	display: flex;
	align-items: center;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 20;
}
.skin-nova .header {
	background-color: #000;
}
.skin-sca .header {
	background-color: #007fe2;
}
.skin-arn .header {
	background-color: #4e39a3;
}
.skin-cra .header {
	background: transparent linear-gradient(270deg,  #E81F76 0%,  #00AEEF 100%) 0% 0% no-repeat padding-box;
}
.skin-ace .header {
	background-color: #96356e;
}
.header .logo-hold {
	height: 50px;
	display: flex;
	align-items: center;
}
.header .logo-hold img {
	height: 100%;
}
.navlogos {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>