<template>
	<div class="station-selections">
		<div class="selitemholder">
			<div class="selitem" @click="toggler('hit')" :class="{selected : selected.includes('hit')}">Hit</div>
			<div class="selitem" @click="toggler('mmm')" :class="{selected : selected.includes('mmm')}">Triple M</div>
			<div class="selitem" @click="toggler('kiis')" :class="{selected : selected.includes('kiis')}">KIIS</div>
			<div v-if="![14,19,72].includes(marketid)" class="selitem" @click="toggler('nova')" :class="{selected : selected.includes('nova')}">Nova</div>
			<div v-if="![14,19,72,112,114].includes(marketid)" class="selitem" @click="toggler('puregold')" :class="{selected : selected.includes('puregold')}">Pure Gold</div>
			<div v-if="![14,19,72].includes(marketid)" class="selitem" @click="toggler('smooth')" :class="{selected : selected.includes('smooth')}">Smooth</div>
			<div class="selitem" @click="toggler('jjj')" :class="{selected : selected.includes('jjj')}">Triple J</div>
			<div v-if="[112].includes(marketid)" class="selitem" @click="toggler('bh')" :class="{selected : selected.includes('bh')}">4BH</div>
			<div v-if="[113].includes(marketid)" class="selitem" @click="toggler('aa')" :class="{selected : selected.includes('aa')}">5AA</div>
<!--			<div v-if="showrecentsurvoption === true" class="selitem" style="margin-left: 40px;" :class="{selected : recentOnly}" @click="toggleRecent">Recent Only</div>-->
		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'

export default defineComponent({
	name: "SCAStationToggler",
	mixins: [globalMixin],
	components: {},
	props: {
		showrecentsurvoption: {
			type: Boolean,
			default: false,
		},
		maxallowedselect: {
			type: Number,
			default: 10,
		},
		marketid: {
			type: Number,
			default: 0,
		},
		// initialselection: {
		// 	type: String,
		// 	default: '',
		// },
	},
	data() {
		return {
			store: store,

			//selected: ['hit','mmm','kiis','nova','puregold','smooth','jjj'],
			recentOnly: false,
		}
	},
	computed: {
		selected: {
			get() {
				return this.store.scaChosenBrands
			},
			set(value) {
				this.store.scaChosenBrands = value
			}
		},
	},
	methods: {
		toggler(itemname) {
			if(this.selected.includes(itemname)) {
				if(this.selected.length > 1) {
					this.selected = this.selected.filter(item => item !== itemname)
				}
			}
			else {
				if(this.maxallowedselect === 1) this.selected = [itemname]
				else this.selected.push(itemname)
			}
		},
		toggleRecent() {
			if(this.recentOnly === true) this.recentOnly = false
			else this.recentOnly = true
		},
	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped>
.station-selections {
	position: fixed;
	z-index: 9;
	background: var(--greylight);
	width: 100%;
	padding: 10px 20px;
}
.selitemholder {
	display: flex;
	gap: 10px;
}
.selitem {
	border: 1px solid #CCC;
	padding: 5px 10px;
	cursor: pointer;
}
.selitem.selected {
	background-color: #CCC;
}
</style>