<template>
	<div class="inner">
		<!-- ace-6kjda9dlagdsdf-jn1g9js -->
		<div v-if="store.innerPageView === 'home'">
			<HeaderNav></HeaderNav>
			<div v-if="store.userLoggedIn === false" class="page-tile-holder">
				<LoginWidget :showgoogle="true" :showms="false" />
			</div>
			<div v-if="store.userLoggedIn === true" class="page-tile-holder">
				<PageTile tilename="Ace Stations" @click="store.innerPageView = 'Ace Stations'" />
				<PageTile tilename="National Cume" @click="store.innerPageView = 'National Cume'" />
				<PageTile tilename="Analyser" @click="store.innerPageView = 'Analyser'" />
				<PageTile tilename="Quarter Hours" @click="store.innerPageView = 'Quarter Hours'" />
				<PageTile tilename="P1/P2 Report" @click="store.innerPageView = 'P1/P2 Report'" />
			</div>
		</div>
		<div v-else>
			<AceOnlyStations v-if="store.innerPageView === 'Ace Stations'" pagetitle="Ace Stations" />
			<AceNatCume v-if="store.innerPageView === 'National Cume'" pagetitle="National Cume" />
			<DashARNP1s v-if="store.innerPageView === 'P1/P2 Report'" pagetitle="P1/P2 Report" :isace="true" />
			<RatingsChartsAce v-if="store.innerPageView === 'Analyser'" pagetitle="Analyser" />
			<DashAceQtrs v-if="store.innerPageView === 'Quarter Hours'" pagetitle="Quarter Hours" />
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import PageTile from "@/components/PageTile.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import LoginWidget from "@/components/LoginWidget.vue";
import DashARNP1s from "@/components/views/DashARNP1s.vue";
import RatingsChartsAce from "@/components/views/RatingsChartsAce.vue";
import DashAceQtrs from "@/components/views/DashAceQtrs.vue";
import AceOnlyStations from "@/components/views/AceOnlyStations.vue";
import AceNatCume from "@/components/views/AceNatCume.vue";

export default defineComponent({
	name: "DashAce6",
	mixins: [globalMixin],
	components: {
		AceNatCume,
		AceOnlyStations,
		DashAceQtrs,
		RatingsChartsAce,
		DashARNP1s,
		LoginWidget,
		HeaderNav,
		PageTile,
	},
	props: {},
	data() {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped></style>