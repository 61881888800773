import { createApp } from 'vue'
import App from './App.vue'
import VueApexCharts from 'vue3-apexcharts'
import vue3GoogleLogin from 'vue3-google-login'

import '@/includes/style.css'

const app = createApp(App)
app.use(VueApexCharts);
app.use(vue3GoogleLogin, {clientId: '1041247236052-gkudpk4jhioepv3027bl8rls0fsd8cuc.apps.googleusercontent.com'})
app.mount('#app')