<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">Summary</p>
					<select class="select-styled marketchoose" v-model="chosenMarketId">
						<option :value="900">SMBAP National</option>
						<option v-for="m in store.marketListSCA" :value="m.id" :key="m.id">{{m.name}}</option>
					</select>
					<select class="select-styled demogroupchoose" v-model="chosenDemoGroup">
						<option v-for="demo in store.demoGroupsSCA" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled demochoose" v-model="chosenDemo">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<SCAStationToggler :marketid="chosenMarketId" :maxallowedselect="1" initialselection="hit"></SCAStationToggler>

		<div class="sca-inner">
			<ChartHolderDash charttitle="Share to All %">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqBarDaypartsX v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="store.returnedData" :chosendayparts="selectedDayparts" :chosenstation="chosenStationId"
						:chosendemo="chosenDemo" chosenstatistic="shareToAll"
						:surveylist="surveyList" :daypartsxaxis="true" :forcedefaultcolors="true"
					></ApexFreqBarDaypartsX>
				</template>
			</ChartHolderDash>

			<ChartHolderDash charttitle="Cume 000s">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqBarDaypartsX v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="store.returnedData" :chosendayparts="selectedDayparts" :chosenstation="chosenStationId"
						:chosendemo="chosenDemo" chosenstatistic="cume"
						:surveylist="surveyList" :daypartsxaxis="true" :forcedefaultcolors="true"
					></ApexFreqBarDaypartsX>
				</template>
			</ChartHolderDash>

			<ChartHolderDash charttitle="Time Spent Listening (hours per week)">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqBarDaypartsX v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="store.returnedData" :chosendayparts="selectedDayparts" :chosenstation="chosenStationId"
						:chosendemo="chosenDemo" chosenstatistic="tslHrsDecimal"
						:surveylist="surveyList" :daypartsxaxis="true" :forcedefaultcolors="true"
					></ApexFreqBarDaypartsX>
				</template>
			</ChartHolderDash>

			<ChartHolderDash charttitle="Average Audience 000s">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqBarDaypartsX v-if="store.returnedData && store.returnedData.length > 0"
						:basedata="store.returnedData" :chosendayparts="selectedDayparts" :chosenstation="chosenStationId"
						:chosendemo="chosenDemo" chosenstatistic="avgAud"
						:surveylist="surveyList" :daypartsxaxis="true" :forcedefaultcolors="true"
					></ApexFreqBarDaypartsX>
				</template>
			</ChartHolderDash>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import ApexFreqBarDaypartsX from "@/components/ApexFreqBarDaypartsX.vue";
import SCAStationToggler from "@/components/SCAStationToggler.vue";

export default defineComponent({
	name: "SCADash",
	mixins: [globalMixin],
	components: {
		SCAStationToggler,
		ApexFreqBarDaypartsX,
		ChartLoaderInsert,
		ChartHolderDash,
		HeaderNav
	},
	props: {},
	data() {
		return {
			store: store,

			selectedDayparts: [
				1, //ms 530-12mn
				3, //mf 530-9a
				47, //mf 9a-3p
				89, //m-f 3p-6p
				23, //m-f 4p-6p
			],
		}
	},
	computed: {
		chosenMarketId: {
			get() {
				return this.store.scaChosenMarketId
			},
			set(val) {
				this.store.scaChosenMarketId = val
			}
		},
		chosenDemoGroup: {
			get() {
				return this.store.scaChosenDemoGroupId
			},
			set(val) {
				this.store.scaChosenDemoGroupId = val
			}
		},
		chosenDemo: {
			get() {
				return this.store.scaChosenDemoId
			},
			set(val) {
				this.store.scaChosenDemoId = val
			}
		},
		chosenDaypart: {
			get() {
				return this.store.scaChosenDaypartId
			},
			set(val) {
				this.store.scaChosenDaypartId = val
			}
		},
		chosenStationId: {
			get() {
				return this.store.scaChosenStationId
			},
			set(val) {
				this.store.scaChosenStationId = val
			}
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		allDemoIds() {
			let d = []
			for(let did of this.store.demoGroupsSCA) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.store.demoGroupsSCA.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				let d = []
				for(let did of dg.dids) {
					let dp = this.store.demoObs.find(item => item.id === did)
					if(dp) d.push(dp)
				}
				return d
			}
			return []
		},
		dayparts() {
			if(this.store.daypartObs) {
				let d = []
				for(let did of this.store.daypartIdsSCA) {
					let dp = this.store.daypartObs.find(item => item.id === did)
					if(dp) d.push(dp)
				}
				return d
			}
			return []
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id)).reverse()
		},
		chartStationIds() {
			if(this.chosenMarketId === 110) return ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222','g_2213']
			else if(this.chosenMarketId === 111) return ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057','g_3213']
			else if(this.chosenMarketId === 112) return ['g_7203','g_7206','g_7214','g_701056','g_7210','g_701014','g_701025']
			else if(this.chosenMarketId === 113) return ['g_8203','g_801056','g_8213','g_8214','g_8204','g_8209','g_8201','g_801024']
			else if(this.chosenMarketId === 114) return ['g_8902','g_8905','g_8914','g_901012','g_8910','g_901021']
			else if(this.chosenMarketId === 19) return ['g_4301','g_4304','g_403003','g_403011','g_4308']
			else if(this.chosenMarketId === 14) return ['g_4817','g_427001','g_427036','g_4813']
			else if(this.chosenMarketId === 72) return ['g_4001','g_4002','g_4004','g_402003','g_4008']
			else if(this.chosenMarketId === 900) {
				return [
					'c_2117', //nova fm excl dab
					'c_2030', //kiis excl dab
					'c_2034', //hit excl dab
					'c_2041', //smooth excl dab
					'c_2032', //pure gold excl dab
					'c_2035', //triple M excl dab
					'c_120', //triple j
				]
			}
			return []
		},
		chartStationList() {
			if(this.store.stationObs) {
				return this.store.stationObs.filter(item => this.chartStationIds.includes(item.id))
			}
			return []
		},
	},
	methods: {
		prepareDataFetch() {
			if(this.store.dashId && this.store.dashId.length > 0) {
				let sets = []
				let type = 'standard'
				if(this.chosenMarketId === 900) type = 'smbap'
				sets.push({
					type: type,
					dayparts: this.selectedDayparts,
					demos: [this.chosenDemo],
					statistics: ['cume', 'shareToAll', 'tslHrsDecimal', 'avgAud'],
					surveylist: this.surveyListIds,
					stationIds: this.chartStationIds,
					market: this.chosenMarketId,
					audioTypeIds: [0],
				})
				this.doGetCacheDataAudology(sets)
			}
		},
		setSelectedBrand() {
			let brandSelected = this.store.scaChosenBrands[0]
			this.chosenStationId = this.chartStationIds.find(item => this.store.brandstations[brandSelected].includes(item))
		},
	},
	watch: {
		chosenMarketId() {
			this.setSelectedBrand()
			this.prepareDataFetch()
		},
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenDaypart() {
			this.prepareDataFetch()
		},
		chosenDemoGroup() {
			this.chosenDemo = this.demoDisplay[0].id
		},
		'store.scaChosenBrands'() {
			this.setSelectedBrand()
		},
	},
	mounted() {
		if(this.store.scaChosenBrands.length > 0) {
			this.store.scaChosenBrands = this.store.scaChosenBrands.slice(0,1)
		}
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>