<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="line"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ApexFreqLineQtrSingleStn",
	mixins: [globalMixin],
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,

		chosenstatistic: String,
		chosendemo: Number,
		chosendaypart: Number,
		chosenradiotype: {
			type: Number,
			default: 0
		},
		showmarkers: {
			type: Boolean,
			default: true,
		},
		basedata: Array,
		chosenstation: String,
		chosensurveys: Array,
	},
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
		}
	},
	computed: {
		chartdata() {
			let rows = []
			if(!this.basedata || this.basedata.length === 0 || !this.chosenstation) return null
			for(let su of this.chosensurveys) {
				let myrow = []
				myrow = {
					name: su.nicename,
					data: [],
				}
				for(let qtr of this.store.qtrHrObs) {
					let bd = this.basedata.find(item => item.survcode === su.id)
					if(bd) {
						let valarr = bd.data.find(item => item.stid === this.chosenstation)
						if (valarr) {
							let valob = valarr.qtrs.find(item => item.qtr === qtr.id)
							if (valob) {
								let val = valob.val
								if (val)myrow.data.push({x: qtr.name, y: val})
								else myrow.data.push({x: qtr.name, y: 0})
							}
							else myrow.data.push({x: qtr.name, y: 0})
						}
					}
				}
				rows.push(myrow)
			}
			return rows
		},
		chartcolors() {
			return this.store.defaultColors
		},
	},
	methods: {
		updateColours(isFirstLoad = false) {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			if(isFirstLoad) {
				this.chartoptions.colors = outcols;
			}
			else { //only for subsequent loads/updates - otherwise chart is duplicated
				this.chartoptions = {
					...this.chartoptions, ...{
						colors: outcols
					}
				};
			}
		},
	},
	watch: {
		chartcolors() {
			this.updateColours()
		}
	},
	mounted() {
		if(this.showmarkers === false) {
			this.chartoptions.markers.size = 0
		}
		else {
			this.chartoptions.markers.size = 4
		}
		this.updateColours(true)
	}
}
</script>

<style scoped>

</style>