<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">{{pagetitle}}</p>
					<select class="select-styled demogroupchoose" v-model="chosenDemoGroup">
						<option v-for="demo in store.demoGroupsSCA" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled demochoose" v-model="chosenDemo">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<SCAStationToggler :marketid="110" :showrecentsurvoption="true"></SCAStationToggler>

		<div class="sca-inner">
			<br/>
			<div v-for="(md, ind) in mktanddayparts" :key="ind">
				<DaypartShareCumeTSLSplit :marketid="md.marketId" :daypartid="md.daypartId" :heading="getMarketName(md.marketId)+': '+getDaypartName(md.daypartId)"
					:chosendemo="chosenDemo" :surveylist="getSurveyObsForMarket(md.marketId)" :chartstationlist="getChartStationList(md.marketId)"
				></DaypartShareCumeTSLSplit>
			</div>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";
import DaypartShareCumeTSLSplit from "@/components/views/DaypartShareCumeTSLSplit.vue";
import SCAStationToggler from "@/components/SCAStationToggler.vue";

export default defineComponent({
	name: "SCADaypartReview",
	mixins: [globalMixin],
	components: {
		SCAStationToggler,
		DaypartShareCumeTSLSplit,
		HeaderNav
	},
	props: {
		mktanddayparts: Array, //[{marketId: 110, daypartId: 1}, {marketId: 110, daypartId: 17}]
		pagetitle: String,
	},
	data() {
		return {
			store: store,

		}
	},
	computed: {
		chosenDemoGroup: {
			get() {
				return this.store.scaChosenDemoGroupId
			},
			set(val) {
				this.store.scaChosenDemoGroupId = val
			}
		},
		chosenDemo: {
			get() {
				return this.store.scaChosenDemoId
			},
			set(val) {
				this.store.scaChosenDemoId = val
			}
		},
		activeMarketObs() {
			return this.store.marketObs.filter(item => [110,111,112,113,114,900].includes(item.id))
		},
		allDemoIds() {
			let d = []
			for(let did of this.store.demoGroupsSCA) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.store.demoGroupsSCA.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				let d = []
				for(let did of dg.dids) {
					let dp = this.demoObs.find(item => item.id === did)
					if(dp) d.push(dp)
				}
				return d
			}
			return []
		},
		surveyListIds() {
			let s = []
			if(this.activeMarketObs) {
				for(let it of this.activeMarketObs) {
					if(it.surveys) {
						for(let sitem of it.surveys) {
							if(!s.includes(sitem)) s.push(sitem)
						}
					}
				}
			}
			return s
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		neededDayparts() {
			let d = []
			for(let it of this.mktanddayparts) {
				if(!d.includes(it.daypartId)) d.push(it.daypartId)
			}
			return d
		},
	},
	methods: {
		getSurveyObsForMarket(mktid) {
			return this.surveyList.filter(item => item.mktid === mktid)
		},
		getChartStationList(mktid) {
			let myStnIds = this.getChartStationIds(mktid)
			if(this.store.stationObs) {
				for(let brand in this.store.brandstations) {
					if(!this.store.scaChosenBrands.includes(brand)) {
						myStnIds = myStnIds.filter(item => !this.store.brandstations[brand].includes(item))
					}
				}
				return this.store.stationObs.filter(item => myStnIds.includes(item.id))
			}
			return []
		},
		getChartStationIds(mktid) {
			if(mktid === 110) return ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222','g_2213']
			else if(mktid === 111) return ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057','g_3213']
			else if(mktid === 112) return ['g_7203','g_7206','g_7214','g_701056','g_7210','g_701025']
			else if(mktid === 113) return ['g_8203','g_801056','g_8213','g_8214','g_8204','g_8209','g_8201','g_801024']
			else if(mktid === 114) return ['g_8902','g_8905','g_8914','g_901012','g_8910','g_901021']
			else if(mktid === 19) return ['g_4301','g_4304','g_403003','g_403011','g_4308']
			else if(mktid === 14) return ['g_4817','g_427001','g_427036','g_4813']
			else if(mktid === 72) return ['g_4001','g_4002','g_4004','g_402003','g_4008']
			else if(mktid === 900) {
				return [
					'c_2117', //nova fm excl dab
					'c_2030', //kiis excl dab
					'c_2034', //hit excl dab
					'c_2041', //smooth excl dab
					'c_2032', //pure gold excl dab
					'c_2035', //triple M excl dab
					'c_120', //triple j
				]
			}
			return []
		},
		prepareDataFetch() {
			if(this.store.dashId && this.store.dashId.length > 0) {
				let sets = []
				sets.push({
					type: 'standard',
					dayparts: this.neededDayparts,
					demos: [this.chosenDemo],
					statistics: ['cume', 'shareToAll', 'tslHrsDecimal'],
					surveylist: this.surveyListIds,
					stationIds: [
						'g_2207','g_2209','g_2217','g_201009','g_201056','g_2222','g_2213',
						'g_3207','g_3208','g_3217','g_3223','g_301056','g_301057','g_3213',
						'g_7203','g_7206','g_7214','g_701056','g_7210',
						'g_8201','g_8203','g_801056','g_8213','g_8214','g_8204','g_8209','g_8201',
						'g_8902','g_8905','g_8914','g_901012','g_8910',
						'g_4301','g_4304','g_403003','g_403011','g_4308',
						'g_4817','g_427001','g_427036','g_4813',
						'g_4001','g_4002','g_4004','g_402003','g_4008',
						'g_701025','g_801024','g_901021'
					],
					market: null,
					audioTypeIds: [0],
				})
				sets.push({
					type: 'smbap',
					dayparts: this.neededDayparts,
					demos: [this.chosenDemo],
					statistics: ['cume', 'shareToAll', 'tslHrsDecimal'],
					surveylist: this.surveyListIds,
					stationIds: [
						'c_2117', //nova fm excl dab
						'c_2030', //kiis excl dab
						'c_2034', //hit excl dab
						'c_2041', //smooth excl dab
						'c_2032', //pure gold excl dab
						'c_2035', //triple M excl dab
						'c_120', //triple j
					],
					market: null,
					audioTypeIds: [0],
				})
				this.doGetCacheDataAudology(sets)
			}
		},
	},
	watch: {
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenDemoGroup() {
			this.chosenDemo = this.demoDisplay[0].id
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>