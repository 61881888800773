<template>
	<div class="inner">
		<!-- nova-entertainment-9374dz83d-dadx56dha -->
		<div v-if="store.innerPageView === 'home'">
			<HeaderNav></HeaderNav>
			<div v-if="store.userLoggedIn === false" class="page-tile-holder">
				<LoginWidget />
			</div>
			<div v-if="store.userLoggedIn === true" class="page-tile-holder">
				<PageTile tilename="National" @click="store.innerPageView = 'National'" />
				<PageTile tilename="Sydney" @click="store.innerPageView = 'Sydney'" />
				<PageTile tilename="Melbourne" @click="store.innerPageView = 'Melbourne'" />
				<PageTile tilename="Brisbane" @click="store.innerPageView = 'Brisbane'" />
				<PageTile tilename="Adelaide" @click="store.innerPageView = 'Adelaide'" />
				<PageTile tilename="Perth" @click="store.innerPageView = 'Perth'" />
				<PageTile tilename="GE" @click="store.innerPageView = 'GE'" />
				<PageTile tilename="DAB+" @click="store.innerPageView = 'DAB'" />
				<PageTile tilename="Radio Types (Streaming)" @click="store.innerPageView = 'Streaming'" />
				<PageTile tilename="P1/P2 Report" @click="store.innerPageView = 'P1/P2 Report'" />
			</div>
		</div>
		<div v-else>
			<DashNovaMarket v-if="store.innerPageView === 'Sydney'" :marketid="110" pagetitle="Sydney" />
			<DashNovaMarket v-if="store.innerPageView === 'Melbourne'" :marketid="111" pagetitle="Melbourne" />
			<DashNovaMarket v-if="store.innerPageView === 'Brisbane'" :marketid="112" pagetitle="Brisbane" />
			<DashNovaMarket v-if="store.innerPageView === 'Adelaide'" :marketid="113" pagetitle="Adelaide" />
			<DashNovaMarket v-if="store.innerPageView === 'Perth'" :marketid="114" pagetitle="Perth" />
			<DashNovaMarket v-if="store.innerPageView === 'National'" :marketid="900" pagetitle="Network SMBAP" />
			<DashNovaGE v-if="store.innerPageView === 'GE'"></DashNovaGE>
			<DashNovaDAB v-if="store.innerPageView === 'DAB'"></DashNovaDAB>
			<DashNovaRadioTypes v-if="store.innerPageView === 'Streaming'"></DashNovaRadioTypes>
			<DashNovaP1s v-if="store.innerPageView === 'P1/P2 Report'" pagetitle="P1/P2 Report" />
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import PageTile from "@/components/PageTile.vue";
import DashNovaMarket from "@/components/views/DashNovaMarket.vue";
import DashNovaGE from "@/components/views/DashNovaGE.vue";
import DashNovaDAB from "@/components/views/DashNovaDAB.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import DashNovaRadioTypes from "@/components/views/DashNovaRadioTypes.vue";
import LoginWidget from "@/components/LoginWidget.vue";
import DashNovaP1s from "@/components/views/DashNovaP1s.vue";

export default defineComponent({
	name: "DashNova1",
	mixins: [globalMixin],
	components: {
		DashNovaP1s,
		LoginWidget,
		DashNovaRadioTypes,
		HeaderNav,
		DashNovaDAB,
		DashNovaGE,
		DashNovaMarket,
		PageTile,
	},
	props: {},
	data() {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped></style>