<template>
	<div class="leftside">
		<div class="section-heading">SCA Survey Summaries</div>
		<div class="section" @click="store.innerPageView = 'sca-summary'" :class="{active : store.innerPageView === 'sca-summary'}">
			Stations Share/Cume/TSL Summary
		</div>
		<div class="section" @click="store.innerPageView = 'sca-dash'" :class="{active : store.innerPageView === 'sca-dash'}">
			Stations Share/Cume/TSL Dashboard
		</div>
		<div class="section-heading">Five Year Cells</div>
		<div class="section" @click="store.innerPageView = 'sca-fiveyear'" :class="{active : store.innerPageView === 'sca-fiveyear'}">
			Five Year Age Breakouts
		</div>
		<div class="section" @click="store.innerPageView = 'sca-fiveyeargender'" :class="{active : store.innerPageView === 'sca-fiveyeargender'}">
			Five Year Age Genders
		</div>
		<div class="section-heading">Quarter Hour Charts</div>
		<div class="section" @click="store.innerPageView = 'sca-qtrbycompetitor'" :class="{active : store.innerPageView === 'sca-qtrbycompetitor'}">
			Qtr Hr: By Competitor
		</div>
		<div class="section" @click="store.innerPageView = 'sca-qtrthislast'" :class="{active : store.innerPageView === 'sca-qtrthislast'}">
			Qtr Hr: This vs Last
		</div>
		<div class="section-heading">Survey Trends By Competitor</div>
		<div class="section" @click="store.innerPageView = 'sca-fmshare'" :class="{active : store.innerPageView === 'sca-fmshare'}">
			Competitors: FM Share To All %
		</div>
		<div class="section" @click="store.innerPageView = 'sca-fmcume'" :class="{active : store.innerPageView === 'sca-fmcume'}">
			Competitors: FM Cume 000s
		</div>
		<div class="section" @click="store.innerPageView = 'sca-fmtsl'" :class="{active : store.innerPageView === 'sca-fmtsl'}">
			Competitors: FM TSL
		</div>
		<div class="section" @click="store.innerPageView = 'sca-fmavg'" :class="{active : store.innerPageView === 'sca-fmavg'}">
			Competitors: FM Avg Aud
		</div>
		<div class="section-heading">Music/Workday Reports</div>
		<div class="section" @click="store.innerPageView = 'sca-musicworkday'" :class="{active : store.innerPageView === 'sca-musicworkday'}">
			Music/Workday Summary
		</div>
		<div class="section" @click="store.innerPageView = 'sca-doubletues'" :class="{active : store.innerPageView === 'sca-doubletues'}">
			Tuesdays
		</div>
		<div class="section" @click="store.innerPageView = 'sca-rnbfri'" :class="{active : store.innerPageView === 'sca-rnbfri'}">
			Fridays
		</div>
		<div class="section" @click="store.innerPageView = 'sca-football'" :class="{active : store.innerPageView === 'sca-football'}">
			Triple M Football
		</div>
		<div class="section" @click="store.innerPageView = 'sca-football-summary'" :class="{active : store.innerPageView === 'sca-football-summary'}">
			Triple M Football Dayparts
		</div>
		<div class="section" @click="store.innerPageView = 'sca-other-summary'" :class="{active : store.innerPageView === 'sca-other-summary'}">
			Other Dayparts
		</div>
		<div class="section-heading">Split Reports</div>
		<div class="section" @click="store.innerPageView = 'sca-radiotypes'" :class="{active : store.innerPageView === 'sca-radiotypes'}">
			Radio Types
		</div>
		<div class="section" @click="store.innerPageView = 'sca-polcume'" :class="{active : store.innerPageView === 'sca-polcume'}">
			POL: By Station
		</div>
		<div class="section" @click="store.innerPageView = 'sca-polcumebycomp'" :class="{active : store.innerPageView === 'sca-polcumebycomp'}">
			POL: By Competitor
		</div>
		<div class="section" @click="store.innerPageView = 'sca-regioncume'" :class="{active : store.innerPageView === 'sca-regioncume'}">
			Region: By Station
		</div>
		<div class="section" @click="store.innerPageView = 'sca-regioncompetitors'" :class="{active : store.innerPageView === 'sca-regioncompetitors'}">
			Region: By Competitor
		</div>
		<div class="section" @click="store.innerPageView = 'sca-hmlcume'" :class="{active : store.innerPageView === 'sca-hmlcume'}">
			HML: By Station
		</div>
		<div class="section" @click="store.innerPageView = 'sca-hmlcompetitors'" :class="{active : store.innerPageView === 'sca-hmlcompetitors'}">
			HML: By Competitor
		</div>
		<div class="section" @click="store.innerPageView = 'sca-p1s'" :class="{active : store.innerPageView === 'sca-p1s'}">
			P1/P2s Analysis
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'

export default defineComponent({
	name: "SCALeftMenu",
	mixins: [globalMixin],
	components: {},
	props: {},
	data() {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped>
.leftside {
	width: 300px;
	height: calc(100vh - 70px);
	overflow-y: auto;
	position: fixed;
	left: 0;
	top: 70px;
	background: var(--greylight);
	padding: 10px 0;
}
.section-heading {
	text-transform: uppercase;
	font-weight: bold;
	padding: 13px 20px 3px;
	transition: background 0.2s ease-in-out;
}
.section {
	cursor: pointer;
	padding: 10px 20px;
	font-size: 13px;
}
.section:hover, .section.active {
	background: #CCC;
}
</style>