<template>
	<div class="chart-holder" :class="classes" :style="'height: '+this.holderHeight+'px;'">
		<div class="chart-holder-content">
			<div v-if="!hideheadsection" class="headsection">
				<p class="chtitle">
					{{charttitle}}
					<span v-if="showzoomoption" class="zoomspan" @click="zoomer">
						<i v-if="!zoomed" class="mdi mdi-magnify-plus-outline"></i>
						<i v-else class="mdi mdi-close"></i>
					</span>
				</p>
				<div style="display: flex; align-items: center;">
					<slot name="headerRight"></slot>
				</div>
			</div>
			<div class="chart-container" :style="'height: '+this.chartHeight+'px;'">
				<div style="width: 100%; height: 100%;">
					<slot name="default"></slot>
				</div>
			</div>
			<div v-if="source" style="padding: 4px 10px 10px;"><p style="font-size: 10px; color: #888;">Source: {{source}}</p></div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'ChartHolderDash',
	mixins: [globalMixin],
	components: {},
	props: {
		addclasses: String,
		height: Number,
		charttitle: String,
		showzoomoption: {
			type: Boolean,
			default: true,
		},
		hideheadsection: {
			type: Boolean,
			default: false,
		},
		source: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			store: store,
			zoomed: false,
		}
	},
	computed: {
		chartHeight() {
			if(this.zoomed === true) {
				return window.innerHeight - 200
			}
			else {
				if (this.height) return this.height;
				else return 400;
			}
		},
		holderHeight() {
			if (this.height) return this.height + 86
			else return 486
		},
		classes() {
			let cl = ''
			if(this.addclasses) cl = this.addclasses
			if(this.zoomed === true) return 'zoomed '+cl
			else return cl
		},
	},
	methods: {
		zoomer() {
			if(this.zoomed === false) {
				this.zoomed = true
			}
			else {
				this.zoomed = false
			}
		},
	},
	watch: {},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.chart-container {
	padding: 20px 0 0;
	justify-content:center;
	position: relative;
	display: flex;
	width: 100%;
}
.headsection {
	height: 50px;
	padding: 0 15px;
	border-bottom: 1px solid var(--greymid);
	background-color: #FAFAFA;
	display: flex;
	justify-content: space-between;
}
.headsection .chtitle {
	line-height: 1;
	color: #444;
	font-weight: bold;
	font-size: 15px;
	align-items: center;
	display: flex;
}
.quarter .headsection {
	height: 40px;
}
.quarter .headsection .chtitle {
	font-size: 13px;
}
.quarter .chart-container {
	padding-top: 10px;
}
.zoomspan {
	cursor: pointer;
	font-size: 22px;
	margin-left: 10px;
}
.chart-holder.zoomed {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100% !important;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}
.chart-holder.zoomed .chart-holder-content {
	width: calc(100% - 100px);
	height: calc(100% - 100px);
	background: #FFF;
}
</style>
