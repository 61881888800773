<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">{{pagetitle}}</p>
					<select class="select-styled" v-model="marketid">
						<option v-for="m in marketList" :value="m.id" :key="m.id">{{m.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="shareToAll">Share to All</option>
						<option value="cume">Cume 000s</option>
						<option value="tslHrsDecimal">TSL Hours</option>
						<option value="avgAud">Avg Aud 000s</option>
					</select>
					<select class="select-styled" v-model="chosenDemoGroup">
						<option v-for="demo in store.demoGroupsARN" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled" v-model="chosenDemo">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">

			<ChartHolderDash charttitle="Sat-Sun 12mn-12mn">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="baseData.length > 0"
						:basedata="baseData" :chosendaypart="8" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Sat 6am-8am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="64" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sat 8am-10am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="65" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Sat 10am-2pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="66" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sat 9am-12nn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="67" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Sat 12nn-4pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="68" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sun 6am-8am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="69" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Sun 8am-10am" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="70" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sun 10am-2pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="71" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Sun 9am-12nn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="72" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sun 12nn-4pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="73" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ApexFreqBar from "@/components/ApexFreqBar.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import HeaderNav from "@/components/HeaderNav.vue";

export default defineComponent({
	name: "DashARNWeekends",
	mixins: [globalMixin],
	components: {
		HeaderNav,
		ChartLoaderInsert,
		ApexFreqBar,
		ChartHolderDash
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			store: store,
			chosenStatistic: 'shareToAll', //cume
			chosenDemo: 48,
			chosenDemoGroup: 'People',
			marketid: 110,

			allowedQtrHrDayparts: [92, 90, 91],
			chosenQtrHrDaypart: 92,

			marketList: [
				{id: 110, name: 'Sydney'},
				{id: 111, name: 'Melbourne'},
				{id: 112, name: 'Brisbane'},
				{id: 113, name: 'Adelaide'},
				{id: 114, name: 'Perth'},
			],
		}
	},
	computed: {
		allDemoIds() {
			let d = []
			for(let did of this.store.demoGroupsARN) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.store.demoGroupsARN.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				return this.demoObs.filter(item => dg.dids.includes(item.id))
			}
			return []
		},
		chosenMarketId() {
			return this.marketid
		},
		chartStationIds() {
			if(this.marketid === 110) return ['g_201009','g_2217','g_201056','g_2207','g_2222','g_2209','g_2202']
			else if(this.marketid === 111) return ['g_301056','g_3217','g_301057','g_3207','g_3223','g_3208','g_3202']
			else if(this.marketid === 112) return ['g_7203','g_7214','g_701056','g_7206','g_701014','g_7201']
			else if(this.marketid === 113) return ['g_8203','g_8213','g_801056','g_8204','g_8214','g_8201']
			else if(this.marketid === 114) return ['g_8905','g_8914','g_8902','g_901012','g_8903','g_8901']
			return []
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		chartStationList() {
			if(this.store.stationObs) {
				let stns = []
				for(let sid of this.chartStationIds) {
					let stn = this.store.stationObs.find(item => item.id === sid)
					if(stn) stns.push(stn)
				}
				return stns
			}
			return []
		},
		allowedQtrDaypartObs() {
			if(this.store.daypartObs.length === 0) return []
			return this.store.daypartObs.filter(item => this.allowedQtrHrDayparts.includes(item.id))
		},
	},
	methods: {
		prepareDataFetch() {
			let sets = []
			let dayparts = [8,64,65,66,67,68,69,70,71,72,73]
				sets.push({
				type: 'standard',
				dayparts: dayparts,
				demos: [this.chosenDemo],
				statistics: [this.chosenStatistic],
				surveylist: this.surveyListIds,
				stationIds: this.chartStationIds,
				market: this.chosenMarketId,
				audioTypeIds: [0],
			})
			this.doGetCacheDataAudology(sets)
		},
	},
	watch: {
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
		marketid() {
			this.prepareDataFetch()
		},
		chosenDemoGroup() {
			this.chosenDemo = this.demoDisplay[0].id
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>