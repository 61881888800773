<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">POL Competitors Trending</p>
					<select class="select-styled marketchoose" v-model="chosenMarketId">
						<option v-for="m in store.marketListSCA" :value="m.id" :key="m.id">{{m.name}}</option>
					</select>
					<select class="select-styled demochoose" v-model="chosenDemo">
						<option v-for="demo in demoObs" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
					<select class="select-styled daypartchoose" v-model="chosenDaypart">
						<option v-for="dp in daypartObs" :value="dp.id" :key="dp.id">{{dp.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenPOL">
						<option :value="1">Home</option>
						<option :value="2">Car</option>
						<option :value="3">Work</option>
						<option :value="4">Elsewhere</option>
					</select>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="cume">Cume</option>
						<option value="shareToAll">Share</option>
						<option value="avgAud">Avg Aud</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<SCAStationToggler :marketid="chosenMarketId" :showrecentsurvoption="true"></SCAStationToggler>

		<div class="sca-inner">
			<ChartHolderDash :charttitle="mytitle" :height="600">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqLineTrendMulti v-if="baseData.length > 0"
						:basedata="baseData" :chosendaypart="chosenDaypart" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="chartStationList" :surveylist="surveyList" :showmarkers="false" :showdatalabels="true" :chosenpol="chosenPOL"
					></ApexFreqLineTrendMulti>
				</template>
			</ChartHolderDash>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";
import ApexFreqLineTrendMulti from "@/components/ApexFreqLineTrendMulti.vue";
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import SCAStationToggler from "@/components/SCAStationToggler.vue";

export default defineComponent({
	name: "SCAPOLCompare",
	mixins: [globalMixin],
	components: {
		SCAStationToggler,
		ChartLoaderInsert,
		ChartHolderDash,
		ApexFreqLineTrendMulti,
		HeaderNav
	},
	props: {

	},
	data() {
		return {
			store: store,

			chosenPOL: 1,
		}
	},
	computed: {
		chosenMarketId: {
			get() {
				return this.store.scaChosenMarketId
			},
			set(val) {
				this.store.scaChosenMarketId = val
			}
		},
		chosenDemo: {
			get() {
				return this.store.scaChosenDemoId
			},
			set(val) {
				this.store.scaChosenDemoId = val
			}
		},
		chosenDaypart: {
			get() {
				return this.store.scaChosenDaypartId
			},
			set(val) {
				this.store.scaChosenDaypartId = val
			}
		},
		chosenStatistic: {
			get() {
				return this.store.scaChosenStatistic
			},
			set(val) {
				this.store.scaChosenStatistic = val
			}
		},
		mytitle() {
			let t = 'Place of Listening Trending'
			if(this.chosenStatistic === 'cume') return t + ': Cume'
			else if(this.chosenStatistic === 'shareToAll') return t + ': Share %'
			else if(this.chosenStatistic === 'tslHrsDecimal') return t + ': TSL (hours)'
			return t
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		baseData() {
			if(!this.store.returnedPOLData) return []
			return this.store.returnedPOLData.filter(item => item.marketId === this.chosenMarketId)
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.store.demosSuperGlobal.includes(item.id))
		},
		daypartObs() {
			if(this.store.daypartObs) {
				let d = []
				for(let did of this.store.daypartsGlobalSCA) {
					let dob = this.store.daypartObs.find(item => item.id === did)
					if(dob) d.push(dob)
				}
				return d
			}
			return []
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		chartStationIds() {
			if(this.chosenMarketId === 110) return ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222','g_2213']
			else if(this.chosenMarketId === 111) return ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057','g_3213']
			else if(this.chosenMarketId === 112) return ['g_7203','g_7206','g_7214','g_701056','g_7210','g_701014','g_701025']
			else if(this.chosenMarketId === 113) return ['g_8203','g_801056','g_8213','g_8214','g_8204','g_8209','g_8201','g_801024']
			else if(this.chosenMarketId === 114) return ['g_8902','g_8905','g_8914','g_901012','g_8910','g_901021']
			else if(this.chosenMarketId === 19) return ['g_4301','g_4304','g_403003','g_403011','g_4308']
			else if(this.chosenMarketId === 14) return ['g_4817','g_427001','g_427036','g_4813']
			else if(this.chosenMarketId === 72) return ['g_4001','g_4002','g_4004','g_402003','g_4008']
			return []
		},
		chartStationList() {
			if(this.store.stationObs) {
				let stnids = this.chartStationIds
				for(let brand in this.store.brandstations) {
					if(!this.store.scaChosenBrands.includes(brand)) {
						stnids = stnids.filter(item => !this.store.brandstations[brand].includes(item))
					}
				}
				return this.store.stationObs.filter(item => stnids.includes(item.id))
			}
			return []
		},
	},
	methods: {
		prepareDataFetch() {
			if(this.store.dashId && this.store.dashId.length > 0) {
				let sets = []
				let type = 'pol'
				sets.push({
					type: type,
					dayparts: [this.chosenDaypart],
					demos: [this.chosenDemo],
					statistics: ['cume','shareToAll','avgAud'],
					surveylist: this.surveyListIds,
					stationIds: this.chartStationIds,
					market: this.chosenMarketId,
					audioTypeIds: [0],
				})
				this.doGetCacheDataAudology(sets)
			}
		},
	},
	watch: {
		chosenMarketId() {
			this.prepareDataFetch()
		},
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenDaypart() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>