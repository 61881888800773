<template>
	<div class="page-tile">
		<p class="tile-name">{{tilename}}</p>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'

export default defineComponent({
	name: "PageTile",
	mixins: [globalMixin],
	components: {},
	props: {
		tilename: {
			type: String,
		}
	},
	data() {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped>
.page-tile {
	display: flex;
	border: 1px solid var(--greycancel);
	background-color: var(--greycancel);
	color: #FFF;
	padding: 40px;
	width: 200px;
	height: 160px;
	text-align: center;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}
.page-tile:hover {
	background-color: var(--greymid);
}
.skin-nova .page-tile {
	border: 1px solid #000;
	background-color: #000;
}
.skin-nova .page-tile:hover {
	border: 1px solid #444;
	background-color: #444;
}
.skin-arn .page-tile {
	border: 1px solid #4e39a3;
	background-color: #4e39a3;
}
.skin-arn .page-tile:hover {
	border: 1px solid #781499;
	background-color: #781499;
}
.skin-ace .page-tile {
	border: 1px solid #96356e;
	background-color: #96356e;
}
.skin-ace .page-tile:hover {
	border: 1px solid #903060;
	background-color: #903060;
}
.tile-name {
	font-size: 16px;
	font-weight: bold;
}
</style>