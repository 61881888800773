<template>
	<div style="width: 100%;">
		<div v-if="loginfailed === true" style="padding: 20px; text-align: center;">
			Login failed, please try again, or contact us if you think this is in error
			<br/>
			<p style="font-size: 10px; margin-top: 10px;">
				<span style="font-weight: bold;">Login attempt information:</span><br/>
				Dashboard ID: {{loginInfo.dashId}}<br/>
				Email address: {{loginInfo.emailAddr}}<br/>
				Dashboard is set: {{loginInfo.dashIsSet}}<br/>
			</p>
		</div>

		<div v-if="showms === true" class="ms-login-btn" v-on:click="doMSAdLogin">
			<div><img src="@/assets/microsoft-logo.png" /></div>
			<div><span>Sign in with Microsoft account</span></div>
		</div>

		<GoogleLogin v-if="showgoogle === true" :callback="finGoogleLogin"></GoogleLogin>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import axios from "axios";
import * as msal from "@azure/msal-browser"; // MS login

export default defineComponent({
	name: "LoginWidget",
	mixins: [globalMixin],
	components: {},
	props: {
		showms: {
			type: Boolean,
			default: true,
		},
		showgoogle: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			store: store,

			doingMSLogin: false,
			doingGoogleLogin: false,
			accessToken: null,
			adEmail: null,

			loginfailed: false,
			loginInfo: {
				dashIsSet: null,
				emailAddr: null,
				dashId: null,
			},

		}
	},
	computed: {},
	methods: {
		doMSAdLogin() {
			if(this.store.isLocalhost) {
				this.store.userLoggedIn = true
				this.setLocalstorageLogin()
				return
			}
			if(!this.doingMSLogin) {
				let msalcallbackurl = 'https://dashboards.audology.app/sso-callback-ms.php';
				let msalConfig = {
					auth: {
						clientId: '4d63896c-45e9-4829-99fa-9802c806648b',
						authority: "https://login.microsoftonline.com/common/",
						redirectUri: msalcallbackurl,
					}
				};
				this.doingMSLogin = true;
				let self = this
				const msalInstance = new msal.PublicClientApplication(msalConfig);
				msalInstance.acquireTokenPopup({
					scopes: ["User.Read"] // shorthand for https://graph.microsoft.com/User.Read
				}).then((response) => {
					if(response.accessToken) {
						self.accessToken = response.accessToken;
					}
					if(response.account && response.account.username) {
						self.adEmail = response.account.username;
					}
					self.finishMSLogin()
				}).catch((error) => {
					console.log(error); // handle errors
				});
			}
		},
		finishMSLogin() { //check from our server via MS Graph that person is who they say they are
			if(this.doingMSLogin === true && this.accessToken && this.adEmail) {
				let self = this
				axios.post(
					"/post/fin-microsoft-login.php",
					JSON.stringify({
						accessToken: self.accessToken,
						email: self.adEmail,
						dashid: self.store.dashId,
					})
				).then(function(response) {
					let ret = response.data //response.data is returned info
					self.doingMSLogin = false
					if(ret.loginok) { //if this comes back we have logged in successfully
						self.store.userLoggedIn = true
						self.setLocalstorageLogin()
					}
					if(ret.loginInfo) {
						self.loginInfo = ret.loginInfo
					}
					if(ret.error === 1) {
						self.loginfailed = true
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		finGoogleLogin(response) {
			if(this.store.isLocalhost) {
				this.store.userLoggedIn = true
				return
			}
			if(this.doingGoogleLogin === false) {
				this.doingGoogleLogin = true
				let self = this
				axios.post(
					"/post/fin-google-login.php",
					JSON.stringify({
						jwt: response.credential,
					})
				).then(function(response) {
					let ret = response.data //response.data is returned info
					self.doingGoogleLogin = false
					if(ret.loginok) { //if this comes back we have logged in successfully
						self.store.userLoggedIn = true
					}
					if(ret.error === 1) {
						self.loginfailed = true
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped>
.ms-login-btn {
	border: 1px solid #dadce0;
	padding: 8px;
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 216px;
	margin: 0 auto 18px;
	border-radius: 4px;
	cursor: pointer;
}
.ms-login-btn img {
	margin-right: 8px;
}
.ms-login-btn:hover {
	border: 1px solid #d2e3fc;
}
.ms-login-btn .mdi-at {
	font-size: 20px;
	color: var(--bluemain);
	margin-right: 8px;
}
</style>