<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">Nova Group Exec</p>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">

			<div class="chart-rows">
				<ChartHolderDash charttitle="10+ Share Mon-Sun 5.30am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="1" :chosendemo="48" chosenstatistic="shareToAll"
							:chartstationlist="chartgroupStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="25-54 Share Mon-Sun 5.30am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="1" :chosendemo="78" chosenstatistic="shareToAll"
							:chartstationlist="chartgroupStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="10+ Cume Mon-Sun 5.30am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="1" :chosendemo="48" chosenstatistic="cume"
							:chartstationlist="chartgroupStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="25-54 Cume Mon-Sun 5.30am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="1" :chosendemo="78" chosenstatistic="cume"
							:chartstationlist="chartgroupStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<div class="headsection">
					<p class="chtitle" style="margin-bottom: 20px;">
						Avg Aud 000s - Total Mon-Sun 5.30-12mn - {{latestSurveyOb.name}}
						<span @click="exportToExcel('ge_avgaud')" class="excel-link" style="margin-left: 10px;">
						<i class="mdi mdi-file-excel-outline"></i></span>
					</p>
				</div>
				<table class="altbl" id="ge_avgaud">
					<thead>
						<tr class="headrow">
							<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td headingtext">Network</td>
							<td v-for="demo in chartDemoObs" :key="demo.id" data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="headingtext rt">
								{{demo.name}}
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(r, ind) in chartStationList" :key="ind">
							<td data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" data-f-bold="true" class="align-left">
								{{r.name}}
							</td>
							<td v-for="demo in chartDemoObs" :key="demo.id" data-t="n" class="rt" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
								{{numdisplayNdp(getCellData(r.id,latestSurveyOb.id,'avgAud',1,0,demo.id)/1000, 0, false)}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="chart-rows" style="margin-top: 50px;">
				<div class="headsection">
					<p class="chtitle" style="margin-bottom: 20px;">
						Share % - Total Mon-Sun 5.30-12mn - {{latestSurveyOb.name}}
						<span @click="exportToExcel('ge_share')" class="excel-link" style="margin-left: 10px;">
						<i class="mdi mdi-file-excel-outline"></i></span>
					</p>
				</div>
				<table class="altbl" id="ge_share">
					<thead>
						<tr class="headrow">
							<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td headingtext">Network</td>
							<td v-for="demo in chartDemoObs" :key="demo.id" data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="headingtext rt">
								{{demo.name}}
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(r, ind) in chartStationList" :key="ind">
							<td data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" data-f-bold="true" class="align-left">
								{{r.name}}
							</td>
							<td v-for="demo in chartDemoObs" :key="demo.id" data-t="n" class="rt" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
								{{numdisplayNdp(getCellData(r.id,latestSurveyOb.id,'shareToAll',1,0,demo.id), 1, false)}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- overall chart -->
			<div class="chart-rows" style="margin-top: 50px;">
				<ChartHolderDash :charttitle="'Mon-Sun 5.30am-12mn - '+latestSurveyOb.name">
					<template v-slot:headerRight>
						<div>
							<select v-model="overallStatisticChoice" class="select-styled">
								<option v-for="st in statisticChoices" :key="st.id" :value="st.id">{{st.name}}</option>
							</select>
						</div>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="!store.returnedData || store.returnedData.length === 0"></ChartLoaderInsert>
						<ApexFreqBarDemosX v-if="store.returnedData && store.returnedData.length > 0"
							:basedata="store.returnedData" :chosendaypart="1" :chosensurvey="latestSurveyOb.id" :chosendemos="chartDemoIds"
							:chosenstatistic="overallStatisticChoice" :chartstationlist="chartStationList"
							:surveylist="surveyList" :demosxaxis="true" :forcedefaultcolors="true"
						></ApexFreqBarDemosX>
					</template>
				</ChartHolderDash>
			</div>

			<!-- repeat for BMAD -->

			<div class="chart-rows">
				<div class="headsection">
					<p class="chtitle" style="margin-bottom: 20px;">
						Avg Aud 000s - Mon-Fri 5.30-7pm - {{latestSurveyOb.name}}
						<span @click="exportToExcel('ge_avgaud_bmad')" class="excel-link" style="margin-left: 10px;">
						<i class="mdi mdi-file-excel-outline"></i></span>
					</p>
				</div>
				<table class="altbl" id="ge_avgaud_bmad">
					<thead>
					<tr class="headrow">
						<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td headingtext">Network</td>
						<td v-for="demo in chartDemoObs" :key="demo.id" data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="headingtext rt">
							{{demo.name}}
						</td>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(r, ind) in chartStationList" :key="ind">
						<td data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" data-f-bold="true" class="align-left">
							{{r.name}}
						</td>
						<td v-for="demo in chartDemoObs" :key="demo.id" data-t="n" class="rt" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
							{{numdisplayNdp(getCellData(r.id,latestSurveyOb.id,'avgAud',19,0,demo.id)/1000, 0, false)}}
						</td>
					</tr>
					</tbody>
				</table>
			</div>

			<div class="chart-rows" style="margin-top: 50px;">
				<div class="headsection">
					<p class="chtitle" style="margin-bottom: 20px;">
						Share % - Mon-Fri 5.30-7pm - {{latestSurveyOb.name}}
						<span @click="exportToExcel('ge_share_bmad')" class="excel-link" style="margin-left: 10px;">
						<i class="mdi mdi-file-excel-outline"></i></span>
					</p>
				</div>
				<table class="altbl" id="ge_share_bmad">
					<thead>
					<tr class="headrow">
						<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td headingtext">Network</td>
						<td v-for="demo in chartDemoObs" :key="demo.id" data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="headingtext rt">
							{{demo.name}}
						</td>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(r, ind) in chartStationList" :key="ind">
						<td data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" data-f-bold="true" class="align-left">
							{{r.name}}
						</td>
						<td v-for="demo in chartDemoObs" :key="demo.id" data-t="n" class="rt" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
							{{numdisplayNdp(getCellData(r.id,latestSurveyOb.id,'shareToAll',19,0,demo.id), 1, false)}}
						</td>
					</tr>
					</tbody>
				</table>
			</div>

			<!-- overall chart -->
			<div class="chart-rows" style="margin-top: 50px;">
				<ChartHolderDash :charttitle="'Mon-Fri 5.30am-7pm - '+latestSurveyOb.name">
					<template v-slot:headerRight>
						<div>
							<select v-model="bmadStatisticChoice" class="select-styled">
								<option v-for="st in statisticChoices" :key="st.id" :value="st.id">{{st.name}}</option>
							</select>
						</div>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="!store.returnedData || store.returnedData.length === 0"></ChartLoaderInsert>
						<ApexFreqBarDemosX v-if="store.returnedData && store.returnedData.length > 0"
							:basedata="store.returnedData" :chosendaypart="19" :chosensurvey="latestSurveyOb.id" :chosendemos="chartDemoIds"
							:chosenstatistic="bmadStatisticChoice" :chartstationlist="chartStationList"
							:surveylist="surveyList" :demosxaxis="true" :forcedefaultcolors="true"
						></ApexFreqBarDemosX>
					</template>
				</ChartHolderDash>
			</div>

		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import ApexFreqBarDemosX from "@/components/ApexFreqBarDemosX.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import ApexFreqBar from "@/components/ApexFreqBar.vue";

export default defineComponent({
	name: "DashNovaGE",
	mixins: [globalMixin],
	components: {
		ApexFreqBar,
		HeaderNav,
		ApexFreqBarDemosX,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			store: store,
			chartStationIds: [
				'c_2117', //nova fm excl dab
				'c_2030', //kiis excl dab
				'c_2034', //hit excl dab
				'c_2041', //smooth excl dab
				'c_2032', //pure gold excl dab
				'c_2035', //triple M excl dab
				'c_2120', //10 NE Dash
				'c_2121', //8 ARN Dash
				'c_2122', //10 SCA Dash
			],
			groupStationIds: [
				'c_2120', //10 NE Dash
				'c_2121', //8 ARN Dash
				'c_2122', //10 SCA Dash
				'c_2041', //smooth excl dab
			],

			chartDemoIds: [
				48, //all people
				63, //P10-17
				98, //P10-39
				64, //P18-24
				111, //P18-39
				9010, //P18-44
				65, //P25-39
				372, //P25-44
				78, //P25-54
				1831, //P35-64
			],

			overallStatisticChoice: 'avgAud',
			bmadStatisticChoice: 'avgAud',
			statisticChoices: [
				{id: 'avgAud', name: 'Average Audience'},
				{id: 'shareToAll', name: 'Share %'},
				{id: 'cumePct', name: 'Cume %'},
				{id: 'cume', name: 'Cume Reach'},
			],
		}
	},
	computed: {
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === 900)
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === 900)
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		latestSurveyOb() {
			if(this.surveyList.length > 0) {
				return this.surveyList[0]
			}
			return ''
		},
		chartStationList() {
			if(this.store.stationObs) {
				return this.store.stationObs.filter(item => this.chartStationIds.includes(item.id))
			}
			return []
		},
		chartgroupStationList() {
			if(this.store.stationObs) {
				let arr = []
				for(let s of this.groupStationIds) {
					let ob = this.store.stationObs.find(item => item.id === s)
					if(ob) {
						arr.push(ob)
					}
				}
				return arr
			}
			return []
		},
		chartDemoObs() {
			return this.store.demoObs.filter(item => this.chartDemoIds.includes(item.id))
		},

	},
	methods: {
		prepareDataFetch() {
			let sets = []
			let dayparts = [
				1, //Mon-Sun 5.30-12mn
				19, //Mon-Fri 5.30-7pm
			]
			sets.push({
				type: 'smbap',
				dayparts: dayparts,
				demos: this.chartDemoIds,
				statistics: ['avgAud','shareToAll','cume','cumePct'],
				surveylist: this.surveyListIds,
				stationIds: this.chartStationIds,
				market: 900,
				audioTypeIds: [0],
			})
			this.doGetCacheDataAudology(sets)
		},
		getCellData(stid, survid, statistic, daypart, radiotype, demo) {
			if(!this.store.returnedData || this.store.returnedData.length === 0) return null
			let survitem = this.store.returnedData.find(
				item => item.survcode === survid
					&& item.statistic === statistic
					&& item.daypartId === daypart
					&& item.demoId === demo
					&& item.audioType === radiotype
			)
			if(survitem) {
				let valitem = survitem.data.find(item => item.stid === stid)
				if(valitem) return valitem.val
			}
			return null
		},
	},
	watch: {
		// chosenDemo() {
		// 	this.prepareDataFetch()
		// },
		// chosenStatistic() {
		// 	this.prepareDataFetch()
		// },
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>