<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">P1s Analysis</p>
				</div>
			</template>
		</HeaderNav>

		<div class="sca-inner" style="padding-top: 30px;">
			<P1ReportPage></P1ReportPage>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";
import P1ReportPage from "@/components/P1ReportPage.vue";

export default defineComponent({
	name: "SCAP1s",
	mixins: [globalMixin],
	components: {
		P1ReportPage,
		HeaderNav
	},
	props: {

	},
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped></style>