<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">{{pagetitle}}</p>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="shareToAll">Share to All</option>
						<option value="cume">Cume 000s</option>
						<option value="tslHrsDecimal">TSL Hours</option>
						<option value="avgAud">Avg Aud 000s</option>
					</select>
					<select class="select-styled" v-model="chosenDemoGroup">
						<option v-for="demo in store.demoGroupsNova" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled" v-model="chosenDemo">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">

			<ChartHolderDash charttitle="Mon-Sun 5.30am-12mn">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="baseData.length > 0"
						:basedata="baseData" :chosendaypart="1" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>

			<div class="chart-rows">
				<ChartHolderDash :charttitle="bfname" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="3" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 9am-12nn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="4" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 12pm-4pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="5" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Chrissie Swan Show (Mon-Fri 2pm-4pm)" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="37" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Ricki-Lee, Tim & Joel (Mon-Fri 4pm-6pm)" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="23" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Mon-Fri 4pm-7pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="6" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Late Drive with Ben, Liam & Belle (Mon-Fri 6pm-8pm)" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="118" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Smallzy's Surgery (Mon-Fri 8pm-10pm)" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="!baseData || baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="121" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 7pm-10pm" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="!baseData || baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="39" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Sat-Sun 12am-12mn" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="8" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash charttitle="Mon-Fri 5.30am-7pm (BMAD)" addclasses="half">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="19" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="marketid < 300 && chosenStatistic !== 'tslHrsDecimal'" class="chart-rows">
				<ChartHolderDash charttitle="Quarter Hour Breakout">
					<template v-slot:headerRight>
						<select v-model="chosenQtrHrDaypart" class="select-styled incharthead">
							<option v-for="dp in allowedQtrDaypartObs" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqLineQtrMultiStn :basedata="myQtrHrData" :chartstationlist="chartStationList" :chosenstatistic="chosenStatistic"
						></ApexFreqLineQtrMultiStn>
					</template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Quarter Hour Breakout (previous survey)">
					<template v-slot:headerRight>
						<select v-model="chosenQtrHrDaypart" class="select-styled incharthead">
							<option v-for="dp in allowedQtrDaypartObs" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqLineQtrMultiStn :basedata="prevQtrHrData" :chartstationlist="chartStationList" :chosenstatistic="chosenStatistic"
						></ApexFreqLineQtrMultiStn>
					</template>
				</ChartHolderDash>
			</div>

		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ApexFreqBar from "@/components/ApexFreqBar.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import ApexFreqLineQtrMultiStn from "@/components/ApexFreqLineQtrMultiStn.vue";

export default defineComponent({
	name: "DashNovaMarket",
	mixins: [globalMixin],
	components: {
		ApexFreqLineQtrMultiStn,
		HeaderNav,
		ChartLoaderInsert,
		ApexFreqBar,
		ChartHolderDash
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
		marketid: Number,
	},
	data() {
		return {
			store: store,
			chosenStatistic: 'shareToAll', //cume
			chosenDemo: 48,
			chosenDemoGroup: 'People',

			allowedQtrHrDayparts: [92, 90, 91],
			chosenQtrHrDaypart: 92,

		}
	},
	computed: {
		allowedDemoIds() {
			let demIds = [
				48, //10+
				9010, //18-44
				67, //40+
				66, //4054

				49, //M10+
				373, //M25-44
				151, //M25-54
				1833, //M35-64
				71, //M40+
				238, //M4054

				50, //F10+
				374, //F25-44
				152, //F25-54
				1832, //F35-64
				74, //F40+
				239, //F4054

				63, //P10-17
				98, //P10-39
				64, //P18-24
				111, //P18-39
				65, //P25-39
				372, //P25-44
				78, //25-54
				1831, //35-64
			]
			let avv = []
			if(this.marketid !== 900) {
				avv = [
					860, //10-54
					9024, //2534
					9013, //M10-54
					9015, //M18-44
					9025, //M2534
					9012, //F10-54
					9014, //F18-44
					9026, //F2534
				]
			}

			return demIds.concat(avv)
		},
		bfname() {
			let n = ''
			if(this.marketid === 110) n += 'Fitzy & Wippa with Kate Richie'
			else if(this.marketid === 111) n += 'Jase & Lauren'
			else if(this.marketid === 112) n += "Ash, Luttsy & Susie O'Neill"
			else if(this.marketid === 113) n += 'Jodie & Hayesy'
			else if(this.marketid === 114) n += 'Nathan, Nat & Shaun'
			else n += 'Breakfast'
			n += ' (Mon-Fri 5.30am-9am)'
			return n.trim()
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.allowedDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.store.demoGroupsNova.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				return this.demoObs.filter(item => dg.dids.includes(item.id))
			}
			return []
		},
		chosenMarketId() {
			return this.marketid
		},
		chartStationIds() {
			if(this.marketid === 110) return ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222']
			else if(this.marketid === 111) return ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057']
			else if(this.marketid === 112) return ['g_7203','g_7206','g_7214','g_701056']
			else if(this.marketid === 113) return ['g_8203','g_801056','g_8213','g_8214','g_8204']
			else if(this.marketid === 114) return ['g_8902','g_8905','g_8914','g_901012']
			else if(this.marketid === 900) {
				return [
					'c_2117', //nova fm excl dab
					'c_2030', //kiis excl dab
					'c_2034', //hit excl dab
					'c_2041', //smooth excl dab
					'c_2032', //pure gold excl dab
					'c_2035', //triple M excl dab
					'c_2134', //5aa at national combo
				]
			}
			return []
		},
		demos() {
			if(!this.store.demoObs) return []
			return this.store.demoObs.filter(item => this.allowedDemoIds.includes(item.id))
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		chartStationList() {
			if(this.store.stationObs) {
				return this.store.stationObs.filter(item => this.chartStationIds.includes(item.id))
			}
			return []
		},
		allowedQtrDaypartObs() {
			if(this.store.daypartObs.length === 0) return []
			return this.store.daypartObs.filter(item => this.allowedQtrHrDayparts.includes(item.id))
		},
		baseQtrData() {
			if(!this.store.returnedQtrData) return []
			return this.store.returnedQtrData.filter(item => item.marketId === this.chosenMarketId)
		},
		myQtrHrData() {
			if(this.baseQtrData.length === 0) return []
			return this.baseQtrData.filter(item => item.daypartId === this.chosenQtrHrDaypart
				&& item.demoId === this.chosenDemo && item.statistic === this.chosenStatistic
				&& item.survcode === this.surveyListIds[0] && item.audioType === 0)
		},
		prevQtrHrData() {
			if(this.baseQtrData.length === 0) return []
			return this.baseQtrData.filter(item => item.daypartId === this.chosenQtrHrDaypart
				&& item.demoId === this.chosenDemo && item.statistic === this.chosenStatistic
				&& item.survcode === this.surveyListIds[1] && item.audioType === 0)
		},
	},
	methods: {
		prepareDataFetch() {
			let sets = []
			let dayparts = [1,3,4,5,37,23,6,38,121,8,19,39,118]
			let type = 'standard'
			if(this.marketid === 900) type = 'smbap'
			sets.push({
				type: type,
				dayparts: dayparts,
				demos: [this.chosenDemo],
				statistics: [this.chosenStatistic],
				surveylist: this.surveyListIds,
				stationIds: this.chartStationIds,
				market: this.chosenMarketId,
				audioTypeIds: [0],
			})
			if(this.marketid !== 900) { //individual market only
				sets.push({
					type: 'qtrhrs',
					dayparts: this.allowedQtrHrDayparts,
					demos: [this.chosenDemo],
					statistics: ['shareToAll','cume','avgAud'],
					surveylist: this.surveyListIds,
					stationIds: this.chartStationIds,
					market: this.chosenMarketId,
					audioTypeIds: [0],
				})
			}
			this.doGetCacheDataAudology(sets)
		},
	},
	watch: {
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
		chosenDemoGroup() {
			this.chosenDemo = this.demoDisplay[0].id
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>