<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">Region trending by station</p>
					<select class="select-styled marketchoose" v-model="chosenMarketId">
						<option v-for="m in marketsExCanberra" :value="m.id" :key="m.id">{{m.name}}</option>
					</select>
					<select class="select-styled daypartchoose" v-model="chosenDaypart">
						<option v-for="dp in daypartObs" :value="dp.id" :key="dp.id">{{dp.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="cume">Cume</option>
						<option value="shareToAll">Share</option>
						<option value="avgAud">Avg Aud</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<SCAStationToggler :marketid="chosenMarketId" :maxallowedselect="1" initialselection="hit"></SCAStationToggler>

		<div class="sca-inner">
			<ChartHolderDash charttitle="Region Breakout" :height="600">
				<template v-slot:default>
					<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
					<ApexFreqRegionSingleStn :basedata="baseData" :chosenstation="chosenStationId"
						:chosendaypart="chosenDaypart" :chosenmarketid="chosenMarketId"
						:chosenstatistic="chosenStatistic" :chosensurveys="surveyList" :showmarkers="false"
					></ApexFreqRegionSingleStn>
				</template>
			</ChartHolderDash>
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import SCAStationToggler from "@/components/SCAStationToggler.vue";
import ApexFreqRegionSingleStn from "@/components/ApexFreqRegionSingleStn.vue";

export default defineComponent({
	name: "SCARegionBySurvey",
	mixins: [globalMixin],
	components: {
		ApexFreqRegionSingleStn,
		SCAStationToggler,
		ChartLoaderInsert,
		ChartHolderDash,
		HeaderNav
	},
	props: {},
	data() {
		return {
			store: store,

			chosenStationId: null,

		}
	},
	computed: {
		marketsExCanberra() {
			return this.store.marketListSCA.filter(item => item.id !== 72)
		},
		marketDemoIds() {
			let f = this.store.regionSplitDemoIds.find(item => item.mktid === this.chosenMarketId)
			if(f) return f.dids
			return []
		},
		marketDemoObs() {
			return this.store.demoObs.filter(item => this.marketDemoIds.includes(item.id))
		},
		chosenMarketId: {
			get() {
				return this.store.scaChosenMarketId
			},
			set(val) {
				this.store.scaChosenMarketId = val
			}
		},
		chosenDemoGroup: {
			get() {
				return this.store.scaChosenDemoGroupId
			},
			set(val) {
				this.store.scaChosenDemoGroupId = val
			}
		},
		chosenDemo: {
			get() {
				return this.store.scaChosenDemoId
			},
			set(val) {
				this.store.scaChosenDemoId = val
			}
		},
		chosenStatistic: {
			get() {
				return this.store.scaChosenStatistic
			},
			set(val) {
				this.store.scaChosenStatistic = val
			}
		},
		chosenDaypart: {
			get() {
				return this.store.scaChosenDaypartId
			},
			set(val) {
				this.store.scaChosenDaypartId = val
			}
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.store.demosSuperGlobal.includes(item.id))
		},
		daypartObs() {
			if(this.store.daypartObs) {
				let d = []
				for(let did of this.store.daypartsGlobalSCA) {
					let dob = this.store.daypartObs.find(item => item.id === did)
					if(dob) d.push(dob)
				}
				return d
			}
			return []
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		chartStationIds() {
			if(this.chosenMarketId === 110) return ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222','g_2213']
			else if(this.chosenMarketId === 111) return ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057','g_3213']
			else if(this.chosenMarketId === 112) return ['g_7203','g_7206','g_7214','g_701056','g_7210','g_701014','g_701025']
			else if(this.chosenMarketId === 113) return ['g_8203','g_801056','g_8213','g_8214','g_8204','g_8209','g_8201','g_801024']
			else if(this.chosenMarketId === 114) return ['g_8902','g_8905','g_8914','g_901012','g_8910','g_901021']
			else if(this.chosenMarketId === 19) return ['g_4301','g_4304','g_403003','g_403011','g_4308']
			else if(this.chosenMarketId === 14) return ['g_4817','g_427001','g_427036','g_4813']
			else if(this.chosenMarketId === 72) return ['g_4001','g_4002','g_4004','g_402003','g_4008']
			else if(this.chosenMarketId === 900) {
				return [
					'c_2117', //nova fm excl dab
					'c_2030', //kiis excl dab
					'c_2034', //hit excl dab
					'c_2041', //smooth excl dab
					'c_2032', //pure gold excl dab
					'c_2035', //triple M excl dab
					'c_120', //triple j
				]
			}
			return []
		},
		chartStationList() {
			if(this.store.stationObs) {
				return this.store.stationObs.filter(item => this.chartStationIds.includes(item.id))
			}
			return []
		},
	},
	methods: {
		prepareDataFetch() {
			if(this.store.dashId && this.store.dashId.length > 0) {
				let sets = []
				this.store.returnedData = null
				sets.push({
					type: 'standard',
					dayparts: [this.chosenDaypart],
					demos: this.marketDemoIds,
					statistics: ['cume','shareToAll','avgAud'],
					surveylist: this.surveyListIds,
					stationIds: this.chartStationIds,
					market: this.chosenMarketId,
					audioTypeIds: [0],
				})
				this.doGetCacheDataAudology(sets)
			}
		},
		setSelectedBrand() {
			let brandSelected = this.store.scaChosenBrands[0]
			this.chosenStationId = this.chartStationIds.find(item => this.store.brandstations[brandSelected].includes(item))
			if(!this.chosenStationId) {
				brandSelected = 'hit'
				this.store.scaChosenBrands = [brandSelected]
				this.chosenStationId = this.chartStationIds.find(item => this.store.brandstations[brandSelected].includes(item))
			}
		},
	},
	watch: {
		chosenMarketId() {
			this.setSelectedBrand()
			this.prepareDataFetch()
		},
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenDaypart() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
		'store.scaChosenBrands'() {
			this.setSelectedBrand()
		},
	},
	mounted() {
		if(this.store.scaChosenMarketId === 900) this.store.scaChosenMarketId = 110
		if(this.store.scaChosenBrands.length > 0) {
			this.store.scaChosenBrands = this.store.scaChosenBrands.slice(0,1)
		}
		if(['cume','shareToAll'].includes(this.chosenStatistic) === false) this.chosenStatistic = 'cume'
		this.prepareDataFetch()
		if(!this.chosenStationId) this.chosenStationId = this.chartStationIds[0]
	},
	created() {

	},
})
</script>
<style scoped></style>