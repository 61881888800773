<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">{{pagetitle}}</p>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">
			<P1ReportPage></P1ReportPage>
		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";
import P1ReportPage from "@/components/P1ReportPage.vue";

export default defineComponent({
	name: "DashNovaP1s",
	mixins: [globalMixin],
	components: {
		P1ReportPage,
		HeaderNav,
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped></style>