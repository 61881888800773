<template>
	<div class="inner">
		<!-- arn-7dk349fafg-dazdpqofjf8fs -->
		<div v-if="store.innerPageView === 'home'">
			<HeaderNav></HeaderNav>
			<div v-if="store.userLoggedIn === false" class="page-tile-holder">
				<LoginWidget />
			</div>
			<div v-if="store.userLoggedIn === true" class="page-tile-holder">
				<PageTile tilename="National" @click="store.innerPageView = 'National'" />
				<PageTile tilename="Sydney" @click="store.innerPageView = 'Sydney'" />
				<PageTile tilename="Melbourne" @click="store.innerPageView = 'Melbourne'" />
				<PageTile tilename="Brisbane" @click="store.innerPageView = 'Brisbane'" />
				<PageTile tilename="Adelaide" @click="store.innerPageView = 'Adelaide'" />
				<PageTile tilename="Perth" @click="store.innerPageView = 'Perth'" />
				<PageTile tilename="Gold Coast" @click="store.innerPageView = 'Gold Coast'" />
				<PageTile tilename="DAB" @click="store.innerPageView = 'DAB'" />
				<PageTile tilename="Weekends" @click="store.innerPageView = 'Weekends'" />
				<PageTile tilename="National Dayparts" @click="store.innerPageView = 'National Dayparts'" />
				<PageTile tilename="P1/P2 Report" @click="store.innerPageView = 'P1/P2 Report'" />
				<PageTile tilename="SYD/MEL/BNE" @click="store.innerPageView = 'SYD/MEL/BNE'" />
				<PageTile tilename="SYD/MEL" @click="store.innerPageView = 'SYD/MEL'" />
			</div>
		</div>
		<div v-else>
			<DashARNMarket v-if="store.innerPageView === 'Sydney'" :marketid="110" pagetitle="Sydney" />
			<DashARNMarket v-if="store.innerPageView === 'Melbourne'" :marketid="111" pagetitle="Melbourne" />
			<DashARNMarket v-if="store.innerPageView === 'Brisbane'" :marketid="112" pagetitle="Brisbane" />
			<DashARNMarket v-if="store.innerPageView === 'Adelaide'" :marketid="113" pagetitle="Adelaide" />
			<DashARNMarket v-if="store.innerPageView === 'Perth'" :marketid="114" pagetitle="Perth" />
			<DashARNMarket v-if="store.innerPageView === 'Gold Coast'" :marketid="14" pagetitle="Gold Coast" />
			<DashARNMarket v-if="store.innerPageView === 'National'" :marketid="900" pagetitle="Network SMBAP" />
			<DashARNMarket v-if="store.innerPageView === 'SYD/MEL/BNE'" :marketid="901" pagetitle="SYD/MEL/BNE" />
			<DashARNMarket v-if="store.innerPageView === 'SYD/MEL'" :marketid="902" pagetitle="SYD/MEL" />
			<DashARNDAB v-if="store.innerPageView === 'DAB'" :marketid="110" pagetitle="DAB" />
			<DashARNWeekends v-if="store.innerPageView === 'Weekends'" pagetitle="Weekends" />
			<DashARNShowMultiMarket v-if="store.innerPageView === 'National Dayparts'" pagetitle="National Dayparts" />
			<DashARNP1s v-if="store.innerPageView === 'P1/P2 Report'" pagetitle="P1/P2 Report" />
		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import PageTile from "@/components/PageTile.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import DashARNMarket from "@/components/views/DashARNMarket.vue";
import DashARNDAB from "@/components/views/DashARNDAB.vue";
import DashARNWeekends from "@/components/views/DashARNWeekends.vue";
import DashARNShowMultiMarket from "@/components/views/DashARNShowMultiMarket.vue";
import LoginWidget from "@/components/LoginWidget.vue";
import DashARNP1s from "@/components/views/DashARNP1s.vue";

export default defineComponent({
	name: "DashARN4",
	mixins: [globalMixin],
	components: {
		DashARNP1s,
		DashARNShowMultiMarket,
		LoginWidget,
		DashARNWeekends,
		DashARNDAB,
		DashARNMarket,
		HeaderNav,
		PageTile,
	},
	props: {},
	data() {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {

	},
})
</script>
<style scoped></style>