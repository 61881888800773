<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
<!--					<p class="page-title">{{pagetitle}}</p>-->
					<select class="select-styled" v-model="chosenMarketId">
						<option v-for="opt in marketList" :key="opt.id" :value="opt.id">{{opt.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="shareToAll">Share to All</option>
						<option value="cume">Cume 000s</option>
						<option value="avgAud">Avg Aud 000s</option>
					</select>
					<select class="select-styled" v-model="chosenDemoGroup">
						<option v-for="demo in store.demoGroupsARN" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled" v-model="chosenDemo">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">

			<div v-if="chosenMarketId < 300 && chosenStatistic !== 'tslHrsDecimal'" class="chart-rows">
				<ChartHolderDash charttitle="Quarter Hour Breakout">
					<template v-slot:headerRight>
						<select v-model="aceOnlyStations" class="select-styled incharthead" style="margin-right: 10px;">
							<option :value="false">Commercial</option>
							<option :value="true">Ace Only</option>
						</select>
						<select v-model="chosenQtrHrDaypart" class="select-styled incharthead">
							<option v-for="dp in allowedQtrDaypartObs" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="myQtrHrData.length === 0"></ChartLoaderInsert>
						<ApexFreqLineQtrMultiStn :basedata="myQtrHrData" :chartstationlist="chartStationList"
							:chosenstatistic="chosenStatistic" :showmarkers="false"
						></ApexFreqLineQtrMultiStn>
					</template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Quarter Hour Breakout (previous survey)">
					<template v-slot:headerRight>
						<select v-model="aceOnlyStations" class="select-styled incharthead" style="margin-right: 10px;">
							<option :value="false">Commercial</option>
							<option :value="true">Ace Only</option>
						</select>
						<select v-model="chosenQtrHrDaypart" class="select-styled incharthead">
							<option v-for="dp in allowedQtrDaypartObs" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
					</template>
					<template v-slot:default>
						<ChartLoaderInsert v-if="prevQtrHrData.length === 0"></ChartLoaderInsert>
						<ApexFreqLineQtrMultiStn :basedata="prevQtrHrData" :chartstationlist="chartStationList"
							:chosenstatistic="chosenStatistic" :showmarkers="false"
						></ApexFreqLineQtrMultiStn>
					</template>
				</ChartHolderDash>
			</div>

		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import ApexFreqLineQtrMultiStn from "@/components/ApexFreqLineQtrMultiStn.vue";

export default defineComponent({
	name: "DashAceQtrs",
	mixins: [globalMixin],
	components: {
		ApexFreqLineQtrMultiStn,
		HeaderNav,
		ChartLoaderInsert,
		ChartHolderDash
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			store: store,
			chosenStatistic: 'avgAud', //cume
			chosenMarketId: 111,
			chosenDemo: 48,
			chosenDemoGroup: 'People',
			fetching: false,
			aceOnlyStations: false,

			allowedQtrHrDayparts: [92, 90, 91],
			chosenQtrHrDaypart: 92,

			marketList: [
				{id: 110, name: 'Sydney'},
				{id: 111, name: 'Melbourne'},
				{id: 112, name: 'Brisbane'},
			],
		}
	},
	computed: {
		allDemoIds() {
			let d = []
			for(let did of this.store.demoGroupsARN) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.store.demoGroupsARN.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				return this.demoObs.filter(item => dg.dids.includes(item.id))
			}
			return []
		},
		chartStationIds() {
			if(this.chosenMarketId === 110) {
				if(this.aceOnlyStations === true) return ['g_201021']
				else return ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222','g_201021','g_2202','g_201022']
			}
			else if(this.chosenMarketId === 111) {
				if(this.aceOnlyStations === true) return ['g_301014','g_301018']
				else return ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057','g_301014','g_301018','g_3202','g_3201']
			}
			else if(this.chosenMarketId === 112)
				if(this.aceOnlyStations === true) return ['g_701045']
				else return ['g_7203','g_7206','g_7214','g_701056','g_701014','g_7201','g_701045']
			return []
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				let arr = []
				for(let sob of this.surveyList) {
					arr.push(sob.id)
				}
				return arr
			}
			return []
		},
		surveyList() {
			if(this.activeMarketOb) {
				let sobs = this.store.surveyObs.filter(item => item.mktid === this.activeMarketOb.id)
				let newsobs = []
				for(let s of sobs) {
					if(s.relyear >= 2022) {
						newsobs.push(s)
					}
				}
				return newsobs
			}
			return []
		},
		chartStationList() {
			if(this.store.stationObs) {
				let stns = []
				for(let sid of this.chartStationIds) {
					let stn = this.store.stationObs.find(item => item.id === sid)
					if(stn) stns.push(stn)
				}
				return stns
			}
			return []
		},
		allowedQtrDaypartObs() {
			if(this.store.daypartObs.length === 0) return []
			return this.store.daypartObs.filter(item => this.allowedQtrHrDayparts.includes(item.id))
		},
		baseQtrData() {
			if(!this.store.returnedQtrData) return []
			return this.store.returnedQtrData.filter(item => item.marketId === this.chosenMarketId)
		},
		myQtrHrData() {
			if(this.baseQtrData.length === 0) return []
			return this.baseQtrData.filter(item => item.daypartId === this.chosenQtrHrDaypart
				&& item.demoId === this.chosenDemo && item.statistic === this.chosenStatistic
				&& item.survcode === this.surveyListIds[0] && item.audioType === 0)
		},
		prevQtrHrData() {
			if(this.baseQtrData.length === 0) return []
			return this.baseQtrData.filter(item => item.daypartId === this.chosenQtrHrDaypart
				&& item.demoId === this.chosenDemo && item.statistic === this.chosenStatistic
				&& item.survcode === this.surveyListIds[1] && item.audioType === 0)
		},
	},
	methods: {
		prepareDataFetch() {
			let sets = []
			this.fetching = true
			sets.push({
				type: 'qtrhrs',
				dayparts: this.allowedQtrHrDayparts,
				demos: [this.chosenDemo],
				statistics: ['shareToAll','cume','avgAud'],
				surveylist: this.surveyListIds,
				stationIds: this.chartStationIds,
				market: this.chosenMarketId,
				audioTypeIds: [0],
			})
			this.doGetCacheDataAudology(sets).then(function() {
				this.fetching = false
			}.bind(this))
		},
	},
	watch: {
		chosenMarketId() {
			this.prepareDataFetch()
		},
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
		chosenDemoGroup() {
			this.chosenDemo = this.demoDisplay[0].id
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped>
.select-styled.incharthead {
	width: 240px;
}
</style>