<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="bar"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import ChartLoaderInsert from "@/components/ChartLoaderInsert"
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ApexFreqBarDaypartsX",
	mixins: [globalMixin],
	components: {
		ChartLoaderInsert
	},
	props: {
		// chartdata: Array,
		// chartcolors: Array,
		fetching: Boolean,
		chosenstatistic: String,
		chosenstation: String,
		chosendayparts: Array,
		chosendemo: Number,
		forcedefaultcolors: {
			type: Boolean,
			default: false
		},
		chosenradiotype: {
			type: Number,
			default: 0
		},
		basedata: Array,
		// chartstationlist: Array,
		surveylist: Array,
		daypartsxaxis: {
			type: Boolean,
			default: false
		},
	},
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'bar',
				},
				legend: {
					show: true,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						endingShape: 'rounded',
						columnWidth: '80%',
					},
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: false
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				dataLabels: {
					enabled: false
				},
			},
		}
	},
	computed: {
		myDaypartObs() {
			let d = []
			for(let dob of this.chosendayparts) {
				d.push(this.store.daypartObs.find(item => item.id === dob))
			}
			return d.reverse()
		},
		chartdata() {
			let rows = []
			if(this.myDaypartObs.length === 0) return rows
			if(this.daypartsxaxis === false) { //each daypart is a bar group
				for(let d of this.myDaypartObs) {
					let myrow = []
					myrow = {
						name: d.id,
						data: [],
					}
					for(let s of this.surveylist) {
						myrow.data.push({x: s.nicename, y: this.getCellData(this.chosenstation, s.id, this.basedata, this.chosenstatistic, d.id, this.chosendemo, this.chosenradiotype)})
					}
					rows.push(myrow)
				}
			}
			else { //default where each survey is a bar group
				for(let s of this.surveylist) {
					let myrow = []
					myrow = {
						name: s.nicename,
						data: [],
					}
					for(let d of this.myDaypartObs) {
						myrow.data.push({x: d.name, y: this.getCellData(this.chosenstation, s.id, this.basedata, this.chosenstatistic, d.id, this.chosendemo, this.chosenradiotype)})
					}
					myrow.data.reverse()
					rows.push(myrow)
				}
			}
			return rows
		},
		chartcolors() {
			// if(this.daypartsxaxis === false || this.forcedefaultcolors === true) {
				return this.store.defaultColors
			// }
			// let defaultColorCount = 0
			// let colors = []
			// for(let st of this.chartstationlist) {
			// 	let arr = this.getChartColorForStation(st, defaultColorCount)
			// 	colors.push(arr[0])
			// 	defaultColorCount = arr[1]
			// }
			// return colors
		},
	},
	methods: {
		updateColours(isFirstLoad = false) {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			if(isFirstLoad) {
				this.chartoptions.colors = outcols;
			}
			else { //only for subsequent loads/updates - otherwise chart is duplicated
				this.chartoptions = {
					...this.chartoptions, ...{
						colors: outcols
					}
				};
			}
		},
		setLegend() {
			if(this.daypartsxaxis === false) {
				this.chartoptions.legend.show = false
			}
		},
	},
	watch: {
		chartcolors() {
			this.updateColours()
		},
	},
	mounted() {
		this.updateColours(true)
		this.setLegend()
	}
}
</script>

<style scoped>

</style>