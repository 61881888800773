<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">DAB+ Networks</p>
					<select class="select-styled" v-model="chosenMarketId">
						<option v-for="m in marketList" :value="m.id" :key="m.id">{{m.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenDemo">
						<option v-for="demo in demos" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenDaypart">
						<option v-for="dp in dayparts" :value="dp.id" :key="dp.id">{{dp.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">

			<div class="chart-rows">
				<div class="headsection">
					<p class="chtitle" style="margin-bottom: 20px;">
						{{activeMarketOb.name}} DAB Stations Cume
						<span @click="exportToExcel('dab_stations')" class="excel-link" style="margin-left: 10px;">
						<i class="mdi mdi-file-excel-outline"></i></span>
					</p>
				</div>
				<table class="altbl" id="dab_stations">
					<thead>
					<tr class="headrow">
						<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td headingtext">Station</td>
						<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="headingtext">
							{{latestSurveyOb.name}}
						</td>
						<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="headingtext">
							{{prevSurveyOb.name}}
						</td>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(r, ind) in stationListData" :key="ind">
						<td data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" data-f-bold="true" class="align-left">
							{{r.name}}
						</td>
						<td data-t="n" class="" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
							{{numdisplayNdp(r.val/1000, 0, false)}}
						</td>
						<td data-t="n" class="" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
							{{numdisplayNdp(r.prevVal/1000, 0, false)}}
						</td>
					</tr>
					</tbody>
				</table>
			</div>


		</div>
	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import HeaderNav from "@/components/HeaderNav.vue";

export default defineComponent({
	name: "DashARNDAB",
	mixins: [globalMixin],
	components: {
		HeaderNav,
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			store: store,
			chosenStatistic: 'cume', //cume
			chosenDemo: 48,
			chosenMarketId: 110,
			chosenDaypart: 1,
			chartStationIds: [
				'c_2028', //ARN DAB only
				'c_2033', //SCA DAB only
				'c_2038', //NovaEnt DAB only
			],

			allowedDaypartIds: [1,17,4,5,23,6,8],
			chartDemoIds: [
				48, //all people
				63, //P10-17
				98, //P10-39
				64, //P18-24
				111, //P18-39
				9010, //P18-44
				65, //P25-39
				372, //P25-44
				78, //P25-54
				1831, //P35-64
			],

			marketList: [
				{id: 110, name: 'Sydney'},
				{id: 111, name: 'Melbourne'},
				{id: 112, name: 'Brisbane'},
				{id: 113, name: 'Adelaide'},
				{id: 114, name: 'Perth'},
				{id: 900, name: 'SMBAP'},
			],
		}
	},
	computed: {
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		demos() {
			if(!this.store.demoObs) return []
			return this.store.demoObs.filter(item => this.chartDemoIds.includes(item.id))
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		latestSurveyOb() {
			if(this.surveyList.length > 0) {
				return this.surveyList[0]
			}
			return ''
		},
		prevSurveyOb() {
			if(this.surveyList.length > 1) {
				return this.surveyList[1]
			}
			return ''
		},
		chartStationList() {
			if(this.store.stationObs) {
				return this.store.stationObs.filter(item => this.chartStationIds.includes(item.id))
			}
			return []
		},
		chartDemoObs() {
			return this.store.demoObs.filter(item => this.chartDemoIds.includes(item.id))
		},
		dayparts() {
			if(this.store.daypartObs) {
				let d = []
				for(let did of this.allowedDaypartIds) {
					let dp = this.store.daypartObs.find(item => item.id === did)
					if(dp) d.push(dp)
				}
				return d
			}
			return []
		},
		stationListData() {
			if(!this.store.returnedData || this.store.returnedData.length === 0) return null
			let survitem = this.store.returnedData.find(
				item => item.survcode === this.latestSurveyOb.id
					&& item.statistic === 'cume'
					&& item.daypartId === this.chosenDaypart
					&& item.demoId === this.chosenDemo
					&& item.audioType === 0
			)
			let prevsurvitem = this.store.returnedData.find(
				item => item.survcode === this.prevSurveyOb.id
					&& item.statistic === 'cume'
					&& item.daypartId === this.chosenDaypart
					&& item.demoId === this.chosenDemo
					&& item.audioType === 0
			)
			let dataout = []
			if (survitem) {
				for (let stnres of survitem.data) {
					let stn = null
					if(this.chosenMarketId === 900) stn = this.dabcombos.find(item => item.id === stnres.stid)
					else stn = this.store.stationObs.find(item => item.id === stnres.stid)
					if (stn && (stn.band === 'dab' || this.chosenMarketId === 900)) {
						stnres.name = stn.name
						stnres.prevVal = ''
						if (prevsurvitem) {
							let prevstnres = prevsurvitem.data.find(item => item.stid === stnres.stid)
							if (prevstnres) {
								stnres.prevVal = prevstnres.val
							}
						}
						dataout.push(stnres)
					}
				}
			}
			dataout.sort((a,b) => b.val - a.val);
			return dataout
		},
		dabcombos() {
			return this.store.stationObs.filter(item => item.isCombo === true && item.mktid === 0 && item.networkLevel === 5)
		},
	},
	methods: {
		prepareDataFetch() {
			let sets = []
			if(this.chosenMarketId === 900) {
				let cids = []
				for(let c of this.dabcombos) {
					cids.push(c.id)
				}
				sets.push({
					type: 'smbap',
					dayparts: [this.chosenDaypart],
					demos: [this.chosenDemo],
					statistics: ['cume'],
					surveylist: [this.latestSurveyOb.id, this.prevSurveyOb.id],
					stationIds: cids,
					market: this.chosenMarketId,
					audioTypeIds: [0],
				})
			}
			else {
				sets.push({
					type: 'standard',
					dayparts: [this.chosenDaypart],
					demos: [this.chosenDemo],
					statistics: ['cume'],
					surveylist: [this.latestSurveyOb.id, this.prevSurveyOb.id],
					stationIds: [], //get all stations
					market: this.chosenMarketId,
					audioTypeIds: [0],
				})
			}
			this.doGetCacheDataAudology(sets)
		},
		getCellData(stid, survid, statistic, daypart, radiotype, demo) {
			if(!this.store.returnedData || this.store.returnedData.length === 0) return null
			let survitem = this.store.returnedData.find(
				item => item.survcode === survid
					&& item.statistic === statistic
					&& item.daypartId === daypart
					&& item.demoId === demo
					&& item.audioType === radiotype
			)
			if(survitem) {
				let valitem = survitem.data.find(item => item.stid === stid)
				if(valitem) return valitem.val
			}
			return null
		},
	},
	watch: {
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenDaypart() {
			this.prepareDataFetch()
		},
		chosenMarketId() {
			this.prepareDataFetch()
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>