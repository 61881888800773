<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">{{pagetitle}}</p>
					<select class="select-styled" v-model="chosenDaypartId">
						<option v-for="dayp in daypartObs" :value="dayp.id" :key="dayp.id">{{dayp.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="shareToAll">Share to All</option>
						<option value="cume">Cume 000s</option>
						<option value="tslHrsDecimal">TSL Hours</option>
						<option value="avgAud">Avg Aud 000s</option>
					</select>
					<select class="select-styled" v-model="chosenDemoGroup">
						<option v-for="demo in store.demoGroupsARN" :value="demo.key" :key="demo.key">{{demo.key}}</option>
					</select>
					<select class="select-styled" v-model="chosenDemo">
						<option v-for="demo in demoDisplay" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">

			<ChartHolderDash :charttitle="'Sydney'+appender" addclasses="">
				<template v-slot:default>
					<ChartLoaderInsert v-if="getBaseDataByMarket(110).length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="getBaseDataByMarket(110).length > 0"
						:basedata="getBaseDataByMarket(110)" :chosendaypart="chosenDaypartId" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="getStationListForMarket(110)" :surveylist="getSurveyListObsByMarket(110)" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>
			<ChartHolderDash :charttitle="'Melbourne'+appender" addclasses="">
				<template v-slot:default>
					<ChartLoaderInsert v-if="getBaseDataByMarket(111).length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="getBaseDataByMarket(111).length > 0"
						:basedata="getBaseDataByMarket(111)" :chosendaypart="chosenDaypartId" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="getStationListForMarket(111)" :surveylist="getSurveyListObsByMarket(111)" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>
			<ChartHolderDash :charttitle="'Brisbane'+appender" addclasses="">
				<template v-slot:default>
					<ChartLoaderInsert v-if="getBaseDataByMarket(112).length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="getBaseDataByMarket(112).length > 0"
						:basedata="getBaseDataByMarket(112)" :chosendaypart="chosenDaypartId" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="getStationListForMarket(112)" :surveylist="getSurveyListObsByMarket(112)" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>
			<ChartHolderDash :charttitle="'Adelaide'+appender" addclasses="">
				<template v-slot:default>
					<ChartLoaderInsert v-if="getBaseDataByMarket(113).length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="getBaseDataByMarket(113).length > 0"
						:basedata="getBaseDataByMarket(113)" :chosendaypart="chosenDaypartId" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="getStationListForMarket(113)" :surveylist="getSurveyListObsByMarket(113)" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>

			<ChartHolderDash :charttitle="'Perth'+appender">
				<template v-slot:default>
					<ChartLoaderInsert v-if="getBaseDataByMarket(114).length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="getBaseDataByMarket(114).length > 0"
						:basedata="getBaseDataByMarket(114)" :chosendaypart="chosenDaypartId" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="getStationListForMarket(114)" :surveylist="getSurveyListObsByMarket(114)" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>

			<ChartHolderDash :charttitle="'SMBAP National (note only key demos are available) '+appender">
				<template v-slot:default>
					<ChartLoaderInsert v-if="getBaseDataByMarket(900).length === 0"></ChartLoaderInsert>
					<ApexFreqBar v-if="getBaseDataByMarket(900).length > 0"
						:basedata="getBaseDataByMarket(900)" :chosendaypart="chosenDaypartId" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
						:chartstationlist="getStationListForMarket(900)" :surveylist="getSurveyListObsByMarket(900)" :surveysxaxis="false"
					></ApexFreqBar>
				</template>
			</ChartHolderDash>
		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ApexFreqBar from "@/components/ApexFreqBar.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import HeaderNav from "@/components/HeaderNav.vue";

export default defineComponent({
	name: "DashARNShowMultiMarket",
	mixins: [globalMixin],
	components: {
		HeaderNav,
		ChartLoaderInsert,
		ApexFreqBar,
		ChartHolderDash
	},
	props: {
		pagetitle: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			store: store,
			chosenStatistic: 'shareToAll', //cume
			chosenDemo: 48,
			chosenDemoGroup: 'People',
			marketid: 110,
			daypartOptions: [23,24,6],
			chosenDaypartId: 6,

		}
	},
	computed: {
		allDemoIds() {
			let d = []
			for(let did of this.store.demoGroupsARN) {
				for(let dd of did.dids) {
					d.push(dd)
				}
			}
			return d
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.allDemoIds.includes(item.id))
		},
		demoDisplay() {
			let dg = this.store.demoGroupsARN.find(i => i.key === this.chosenDemoGroup)
			if(dg) {
				return this.demoObs.filter(item => dg.dids.includes(item.id))
			}
			return []
		},
		daypartObs() {
			return this.store.daypartObs.filter(item => this.daypartOptions.includes(item.id))
		},
		appender() {
			let d = this.daypartObs.find(item => item.id === this.chosenDaypartId)
			if(d) return ' '+d.name
			return ''
		},
	},
	methods: {
		getBaseDataByMarket(mktid) {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === mktid)

		},
		getSurveyListIdsByMarket(mktid) {
			let activemktob = this.store.marketObs.find(item => item.id === mktid)
			if(activemktob) {
				return activemktob.surveys
			}
			return []
		},
		getSurveyListObsByMarket(mktid) {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.getSurveyListIdsByMarket(mktid).includes(item.id))
		},
		getStationIdsForMarket(mktid) {
			if(mktid === 110) return ['g_201009','g_2217','g_201056','g_2207','g_2222','g_2209','g_2202']
			else if(mktid === 111) return ['g_301056','g_3217','g_301057','g_3207','g_3223','g_3208','g_3202']
			else if(mktid === 112) return ['g_7203','g_7214','g_701056','g_7206','g_701014','g_7201']
			else if(mktid === 113) return ['g_8203','g_8213','g_801056','g_8204','g_8214','g_8201']
			else if(mktid === 114) return ['g_8905','g_8914','g_8902','g_901012','g_8903','g_8901']
			else if(mktid === 900) {
				return [
					'c_2030', //kiis excl dab
					'c_2117', //nova fm excl dab
					'c_2034', //hit excl dab
					'c_2032', //pure gold excl dab
					'c_2041', //smooth excl dab
					'c_2035', //triple M excl dab
				]
			}
			return []
		},
		getStationListForMarket(mktid) {
			if(this.store.stationObs) {
				let stns = []
				let sids = this.getStationIdsForMarket(mktid)
				for(let sid of sids) {
					let stn = this.store.stationObs.find(item => item.id === sid)
					if(stn) stns.push(stn)
				}
				return stns // this.store.stationObs.filter(item => this.getStationIdsForMarket(mktid).includes(item.id))
			}
			return []
		},
		prepareDataFetch() {
			let sets = []
			let mkts = [110,111,112,113,114,900]
			for(let mktid of mkts) {
				let chartstnids = this.getStationIdsForMarket(mktid)
				let survlistids = this.getSurveyListIdsByMarket(mktid)
				let type = 'standard'
				if(mktid === 900) type = 'smbap'
				sets.push({
					type: type,
					dayparts: [this.chosenDaypartId],
					demos: [this.chosenDemo],
					statistics: [this.chosenStatistic],
					surveylist: survlistids,
					stationIds: chartstnids,
					market: mktid,
					audioTypeIds: [0],
				})
			}
			this.doGetCacheDataAudology(sets)
		},
	},
	watch: {
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
		marketid() {
			this.prepareDataFetch()
		},
		chosenDaypartId() {
			this.prepareDataFetch()
		},
		chosenDemoGroup() {
			this.chosenDemo = this.demoDisplay[0].id
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>