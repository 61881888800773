<template>
	<div>
		<HeaderNav>
			<template v-slot:filterregion>
				<div class="filter-holder">
					<p class="page-title">Radio Types</p>
					<select class="select-styled marketchoose" v-model="chosenMarketId">
						<option v-for="m in store.marketListNova" :value="m.id" :key="m.id">{{m.name}}</option>
					</select>
					<select class="select-styled" v-model="chosenStatistic">
						<option value="shareToAll">Share to All</option>
						<option value="cume">Cume 000s</option>
						<option value="tslHrsDecimal">TSL Hours</option>
						<option value="avgAud">Avg Aud 000s</option>
					</select>
					<select class="select-styled" v-model="chosenDemo">
						<option v-for="demo in demoObs" :value="demo.id" :key="demo.id">{{demo.name}}</option>
					</select>
					<select class="select-styled daypartchoose" v-model="chosenDaypart">
						<option v-for="dayp in daypartObs" :value="dayp.id" :key="dayp.id">{{dayp.name}}</option>
					</select>
				</div>
			</template>
		</HeaderNav>

		<div style="padding-top: 30px;">
			<div class="chart-rows">
				<ChartHolderDash charttitle="Total Radio" addclasses="third">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="chosenDaypart" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false" :chosenradiotype="0"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="AM/FM/DAB Only" addclasses="third">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="chosenDaypart" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false" :chosenradiotype="1"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Streaming Only" addclasses="third">
					<template v-slot:default>
						<ChartLoaderInsert v-if="baseData.length === 0"></ChartLoaderInsert>
						<ApexFreqBar v-if="baseData.length > 0"
							:basedata="baseData" :chosendaypart="chosenDaypart" :chosendemo="chosenDemo" :chosenstatistic="chosenStatistic"
							:chartstationlist="chartStationList" :surveylist="surveyList" :surveysxaxis="false" :chosenradiotype="2"
						></ApexFreqBar>
					</template>
				</ChartHolderDash>
			</div>

		</div>

	</div>
</template>
<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import {defineComponent} from 'vue'
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ApexFreqBar from "@/components/ApexFreqBar.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import HeaderNav from "@/components/HeaderNav.vue";

export default defineComponent({
	name: "DashNovaRadioTypes",
	mixins: [globalMixin],
	components: {
		HeaderNav,
		ChartLoaderInsert,
		ApexFreqBar,
		ChartHolderDash
	},
	props: {

	},
	data() {
		return {
			store: store,
			chosenStatistic: 'shareToAll', //cume
			chosenDemo: 48,
			chosenDaypart: 1,
			chosenMarketId: 110,

		}
	},
	computed: {
		daypartObs() {
			return this.store.daypartObs.filter(item => this.store.daypartsGlobal.includes(item.id))
		},
		demoObs() {
			return this.store.demoObs.filter(item => this.store.demosSuperGlobal.includes(item.id))
		},
		chartStationIds() {
			if(this.chosenMarketId === 110) return ['g_2207','g_2209','g_2217','g_201009','g_201056','g_2222']
			else if(this.chosenMarketId === 111) return ['g_3207','g_3208','g_3217','g_3223','g_301056','g_301057']
			else if(this.chosenMarketId === 112) return ['g_7203','g_7206','g_7214','g_701056']
			else if(this.chosenMarketId === 113) return ['g_8203','g_801056','g_8213','g_8214','g_8204']
			else if(this.chosenMarketId === 114) return ['g_8902','g_8905','g_8914','g_901012']
			return []
		},
		demos() {
			if(!this.store.demoObs) return []
			return this.store.demoObs.filter(item => this.allowedDemoIds.includes(item.id))
		},
		baseData() {
			if(!this.store.returnedData) return []
			return this.store.returnedData.filter(item => item.marketId === this.chosenMarketId)
		},
		activeMarketOb() {
			return this.store.marketObs.find(item => item.id === this.chosenMarketId)
		},
		surveyListIds() {
			if(this.activeMarketOb) {
				return this.activeMarketOb.surveys
			}
			return []
		},
		surveyList() {
			if(this.store.surveyObs.length === 0) return []
			return this.store.surveyObs.filter(item => this.surveyListIds.includes(item.id))
		},
		chartStationList() {
			if(this.store.stationObs) {
				return this.store.stationObs.filter(item => this.chartStationIds.includes(item.id))
			}
			return []
		},
	},
	methods: {
		prepareDataFetch() {
			let sets = []
			let type = 'standard'
			sets.push({
				type: type,
				dayparts: [this.chosenDaypart],
				demos: [this.chosenDemo],
				statistics: [this.chosenStatistic],
				surveylist: this.surveyListIds,
				stationIds: this.chartStationIds,
				market: this.chosenMarketId,
				audioTypeIds: [0,1,2],
			})
			this.doGetCacheDataAudology(sets)
		},
	},
	watch: {
		chosenDemo() {
			this.prepareDataFetch()
		},
		chosenStatistic() {
			this.prepareDataFetch()
		},
		chosenMarketId() {
			this.prepareDataFetch()
		},
		chosenDaypart() {
			this.prepareDataFetch()
		},
	},
	mounted() {
		this.prepareDataFetch()
	},
	created() {

	},
})
</script>
<style scoped></style>